<template>
  <div class="notification-container">
    <div
      v-for="(item, index) in notifications.items"
      v-show="index >= notifications.items.length - 5 && date - item.datetime < 5000"
      :key="'notification_' + item.datetime.getTime()"
      :class="item.class"
      class="notification-balloon"
    >
      <div class="notification-action clickable">
        <div
          class="icon-close"
          @click="$store.commit('removeNotification',{ index: 1 })"
        />
      </div>
      <div class="notification-time">
        {{ $date(item.datetime).format('HH:mm') }}
      </div>
      <div class="notification-message">
        {{ $t('notification.' + item.title, item.data) }}
        <div
          v-if="typeof item.description != 'undefined'"
          class="notification-description"
        >
          {{ $t(item.description) }}
        </div>
      </div>
      <div class="dismiss-progress" />
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      date: new Date(),
      interval: null
    }
  },
  computed: {
    ...mapState({
      notifications: 'notifications',
    })
  },
  mounted: function () {
    this.updateTime();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    updateTime: function () {
      this.interval = setInterval(() => {
        if (this.notifications.items.length > 0) {
          this.date = new Date();
        }
      }, 500);
    }
  }
}
</script>