<template>
  <div>
    {{ $t('helpcontent.asset derivatives extended') }}
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      assets: 'assets'
    })
  }
}
</script>