<template>
  <div>
    {{ $t('helpcontent.register basics') }}
    <a
      class="link clickable highlight no-padding"
      target="_blank"
      :href="'https://' + client.domain + '/register'"
    >{{ ('https://' + client.domain + '/register') }}</a>
    <template v-if="client.email_for_username">
      {{ $t('helpcontent.email is login') }}
    </template>
    <template v-if="!client.email_for_username">
      {{ $t('helpcontent.username is login') }}
    </template>

    <template v-if="client.user_fields.length > 0">
      <h2 class="help-title">
        {{ $t('user fields') }}
      </h2>
      {{ $t('helpcontent.additional user fields during registration') }}:

      <ul>
        <template v-for="(item) in client.user_fields">
          <li
            :key="'user_fields_' + item.name"
            v-if="item.in_registration_form"
          >
            {{ item.title[$i18n.locale] }}
          </li>
        </template>
      </ul>
    </template>

    <h2>{{ $t('helpcontent.password strength') }}</h2>
    {{ $t('helpcontent.password minimum strength', {from: client.min_password_strength, to: 5}) }}


    <br>
    <div class="card tip">
      <div class="card-title">
        {{ $t('helpcontent.test password strength') }}
      </div>
      <input
        type="text"
        :placeholder="$t('password')"
        class="user-input inverted"
        v-model="passwordtest"
      >
      <PasswordStrength
        :password="passwordtest"
        userdata="auth.user"
        @input="strength=$event"
      />
      <div>{{ $t('helpcontent.password strength level', {level: strength}) }}</div>
    </div>
  </div>
</template>
<script>
import PasswordStrength from "@/components/user/PasswordStrength";

export default {
  components: {PasswordStrength},
  data: function () {
    return {
      passwordtest: '',
      strength: 0
    }
  },
  props: {
    auth: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    help: {
      type: Object,
      required: true
    }
  }

}
</script>