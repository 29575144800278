<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      Cancel
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    })
  },
  methods: {
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>