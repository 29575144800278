<template>
  <div class="dialog-content">
    <div
      class="button dialog-button"
      @click="assetClearReference"
    >
      {{ $t('clear reference') }}
    </div>
    {{ $t('select thumbnail to use') }}
    <AssetSelector
      v-model="dialog.properties.reference"
      :hide="[]"
    />
  </div>
</template>
<script>
import {mapState} from "vuex";
import AssetSelector from "@/components/layout/assets/AssetSelector";

export default {
  components: { AssetSelector},
  data: function () {
    return {
      properties: {}
    }
  },
  mounted: function () {

    this.$store.dispatch('assets/loadSingleAsset');
    this.properties = this.dialog.properties;
    this.loaded = true;
  },
  methods: {
    assetClearReference() {

      var confirmation = confirm(this.$t('unlink derivative question'));
      if(!confirmation){
        return;
      }

          var promises = [];

      this.dialog.properties.items.forEach(() => {
        promises.push(this.$store.dispatch(
            'assets/patch',
            {
              items: this.$store.state.assets.selection,
              properties: { 'reference_id': null }
            }
        ));
      });

      Promise.allSettled(promises).then(() => {
        this.$store.commit('addNotification',
            {
              title: 'link disconnected',
              description: '',
              class: 'success',
              datetime: new Date()
            }
        );
        this.$store.dispatch('assets/load');
        this.$store.dispatch('hideDialog');
      });
      this.closeDialog()
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  },
  watch: {
    properties: {
      handler: function () {
        if (this.loaded === true) {
          this.$store.commit('dialogProperties', this.properties);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
    }),
  }
}
</script>