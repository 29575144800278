<template>
  <div
    :class="{ active: dialog.show }"
    class="dialog"
    @mousedown.self.prevent="closeNonModal()"
  >
    <div
      :style="{ maxWidth: dialog.maxWidth + 'px' }"
      class="dialog-frame"
    >
      <div class="dialog-header">
        {{ dialog.title }}
      </div>
      <div
        v-if="dialog.message !== ''"
        class="dialog-content"
        v-html="dialog.message"
      />
      <component
        :is="dialog.bodyComponent"
        v-if="dialog.bodyComponent !== null"
      />
      <component
        :is="dialog.actionComponent"
        v-if="dialog.actionComponent !== null"
        ref="actionComponent"
      />
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Default from "@/components/helpers/dialogactions/Default";
import ActionSharedLinkDelete from "@/components/helpers/dialogactions/ActionSharedLinkDelete";
import ActionAddToCollection from "@/components/helpers/dialogactions/ActionAddToCollection";
import ActionCreateFolder from "@/components/helpers/dialogactions/ActionCreateFolder";
import ActionAddBasketToCollection from "@/components/helpers/dialogactions/ActionAddBasketToCollection";
import ActionDeleteAsset from "@/components/helpers/dialogactions/ActionDeleteAsset";
import ActionArchiveAsset from "@/components/helpers/dialogactions/ActionArchiveAsset";
import ActionUnarchiveAsset from "@/components/helpers/dialogactions/ActionUnarchiveAsset";
import ActionDeleteAssetCollection from "@/components/helpers/dialogactions/ActionDeleteAssetCollection";
import ActionRenameAssetCollection from "@/components/helpers/dialogactions/ActionRenameAssetCollection";
import ActionSuggestTag from "@/components/helpers/dialogactions/ActionSuggestTag";
import ActionExportAsset from "@/components/helpers/dialogactions/ActionExportAsset";
import ActionReplacePreviewAsset from "@/components/helpers/dialogactions/ActionReplacePreviewAsset";
import ActionAssetReference from "@/components/helpers/dialogactions/ActionAssetReference";
import ActionAssetDerivatives from "@/components/helpers/dialogactions/ActionAssetDerivatives";
import ActionUserEdit from "@/components/helpers/dialogactions/ActionUserEdit";
import ActionRoleEdit from "@/components/helpers/dialogactions/ActionRoleEdit";
import ActionTagEdit from "@/components/helpers/dialogactions/ActionTagEdit";
import ActionDeleteUser from "@/components/helpers/dialogactions/ActionDeleteUser";
import ActionDeleteTag from "@/components/helpers/dialogactions/ActionDeleteTag";
import ActionEditFolderHeader from "@/components/helpers/dialogactions/ActionEditFolderHeader";
import ActionEditCollectionSettings from "@/components/helpers/dialogactions/ActionEditCollectionSettings";
import ActionSwitchSortingManual from "@/components/helpers/dialogactions/ActionSwitchSortingManual";
import ActionFolderOptions from "@/components/helpers/dialogactions/ActionFolderOptions";
import ActionUploadOptions from "@/components/helpers/dialogactions/ActionUploadOptions";
import ActionLinkView from "@/components/helpers/dialogactions/ActionLinkView";
import BodyAddToCollection from "@/components/helpers/dialogactions/BodyAddToCollection";
import BodyAddBasketToCollection from "@/components/helpers/dialogactions/BodyAddBasketToCollection";
import BodyExportAsset from "@/components/helpers/dialogactions/BodyExportAsset";
import BodyReplacePreviewAsset from "@/components/helpers/dialogactions/BodyReplacePreviewAsset";
import BodyAssetReference from "@/components/helpers/dialogactions/BodyAssetReference";
import BodyAssetDerivatives from "@/components/helpers/dialogactions/BodyAssetDerivatives";
import BodyRenameAssetCollection from "@/components/helpers/dialogactions/BodyRenameAssetCollection";
import BodyUserEdit from "@/components/helpers/dialogactions/BodyUserEdit";
import BodyRoleEdit from "@/components/helpers/dialogactions/BodyRoleEdit";
import BodyTagEdit from "@/components/helpers/dialogactions/BodyTagEdit";
import BodyEditFolderHeader from "@/components/helpers/dialogactions/BodyEditFolderHeader";
import BodyEditCollectionSettings from "@/components/helpers/dialogactions/BodyEditCollectionSettings";
import BodyCreateFolder from "@/components/helpers/dialogactions/BodyCreateFolder";
import BodyFolderOptions from "@/components/helpers/dialogactions/BodyFolderOptions";
import BodyUploadOptions from "@/components/helpers/dialogactions/BodyUploadOptions";
import BodyLinkView from "@/components/helpers/dialogactions/BodyLinkView";
import BodyDeleteAsset from "@/components/helpers/dialogactions/BodyDeleteAsset";
import AssetSelector from "@/components/layout/assets/AssetSelector";

export default {
  components: {
    Default,
    AssetSelector,
    ActionAddToCollection,
    ActionAddBasketToCollection,
    ActionDeleteAsset,
    ActionArchiveAsset,
    ActionUnarchiveAsset,
    ActionDeleteAssetCollection,
    ActionRenameAssetCollection,
    ActionExportAsset,
    ActionReplacePreviewAsset,
    ActionAssetReference,
    ActionAssetDerivatives,
    ActionSuggestTag,
    ActionSharedLinkDelete,
    ActionUserEdit,
    ActionRoleEdit,
    ActionTagEdit,
    ActionDeleteUser,
    ActionDeleteTag,
    ActionEditFolderHeader,
    ActionEditCollectionSettings,
    ActionSwitchSortingManual,
    ActionCreateFolder,
    ActionFolderOptions,
    ActionUploadOptions,
    ActionLinkView,
    BodyAddToCollection,
    BodyAddBasketToCollection,
    BodyExportAsset,
    BodyReplacePreviewAsset,
    BodyAssetReference,
    BodyAssetDerivatives,
    BodyRenameAssetCollection,
    BodyUserEdit,
    BodyRoleEdit,
    BodyTagEdit,
    BodyEditFolderHeader,
    BodyEditCollectionSettings,
    BodyCreateFolder,
    BodyFolderOptions,
    BodyUploadOptions,
    BodyLinkView,
    BodyDeleteAsset
  },
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
    component: function () {
      if (this.dialog.actionComponent === '') {
        return 'Default';
      }
      return this.dialog.actionComponent;
    }
  },
  mounted: function () {
    window.addEventListener("keydown", this.keyHandler, true);
  },

  beforeDestroy: function () {
    window.removeEventListener("keydown", this.keyHandler, true);
  },
  methods: {
    keyHandler: function (event) {
      if (event.key === "Enter") {
        try {
          this.$refs.actionComponent.defaultAction();
        } catch (e) {
          return;
        }
      }
      if (event.key === "Escape") {
        this.$refs.actionComponent.closeDialog();
      }
    },
    closeNonModal: function () {
      if (this.dialog.modal === false) {
        this.$store.dispatch('hideDialog')
      }
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>