<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('ok') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  mounted: function () {
    this.$store.dispatch('collections/index');
  },
  methods: {
    defaultAction: function () {
      if (this.dialog.properties.new_collection_title !== '') {

        axios.post('api/asset-collections', {name: this.dialog.properties.new_collection_title, type: 'collections', path: '/'})
            .then((response) => {
              this.$store.dispatch('collections/addAssets', {id: response.data.id, items: this.dialog.properties.items}).then(() => {
                this.$store.commit('addNotification',
                    {
                      title: 'collection created and assets added to collection',
                      class: 'highlight',
                      data: {
                        name: this.dialog.properties.new_collection_title,
                        count: this.dialog.properties.items.length
                      },
                      datetime: new Date()
                    }
                );
              });
            })

      } else {

        this.$store.dispatch('collections/addAssets', {id: this.dialog.properties.collection, items: this.dialog.properties.items}).then(() => {

          this.$store.commit('addNotification',
              {
                title: 'assets added to collection',
                class: 'highlight',
                data: {
                  count: this.dialog.properties.items.length
                },
                datetime: new Date()
              }
          );
        });

      }
      this.closeDialog();
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>