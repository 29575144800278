<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button danger"
      @click="defaultAction"
    >
      {{ $t('delete') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  methods: {
    defaultAction: function () {
      axios.delete('api/links/' + this.dialog.properties.item.id)
          .then(() => {
            this.$store.dispatch('hideDialog');
            this.$store.dispatch('assets/sharedLinks');
          }).catch(() => {
        this.$store.dispatch('hideDialog');
        this.$store.dispatch('assets/sharedLinks');
      })
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>