<template>
  <div class="navbar">
    <div class="app-logo">
      <template v-if="$store.state.client.logo">
        <img
          :src="$store.state.client.logo"
          class="app-branding-logo"
          alt="Logo"
        >
      </template>
      <template v-else>
        BRANDHUB - {{ $t($route.name) }}
      </template>
    </div>
    <div class="nav-toggle">
      <div
        v-if="has_transfers"
        :title="$t('current upload transfers',{count: count_transfers })"
        class="navbar-status-icon"
      >
        <div class="icon-upload" />
        <div class="status-number">
          {{ count_transfers }}
        </div>
      </div>

      <div @click="$store.commit('ui/setUserMenuState',!ui.userMenu.active)">
        <div class="icon-menu" />
      </div>

      <UserMenu />
    </div>
  </div>
</template>
<script>


import UserMenu from "./UserMenu";
import {mapState} from "vuex";

export default {
  components: {UserMenu},
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      transfers: 'transfers',
      ui: 'ui'
    }),
    count_transfers: function () {
      var count = 0;
      for (var i = 0; i < this.transfers['primary_queue'].length; i++) {
        if (this.transfers['primary_queue'][i].uploading === true) {
          count++;
        }
      }
      return count;
    },
    has_transfers: function () {
      for (var i = 0; i < this.transfers['primary_queue'].length; i++) {
        if (this.transfers['primary_queue'][i].uploading === true) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>