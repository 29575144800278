<template>
  <div>
    {{ $t('helpcontent.asset export basics') }}

    <ul>
      <template v-for="(format) in assets.exportFormats">
        <li
          :key="'export_format_' + format"
        >
          {{ format }}
        </li>
      </template>
    </ul>
    <h2>{{ $t('helpcontent.asset export filenaming') }}</h2>

    {{ $t('helpcontent.asset export filenaming basics') }}
    <br><br>
    <FilenamingSelector
      :value="[{type:'original'},{type:'extension_lowercase'}]"
      :extension="'jpg'"
      :asset="{extension:'jpg',name:'example_image.jpg' }"
    />
  </div>
</template>
<script>
import {mapState} from "vuex";
import FilenamingSelector from "@/components/helpers/FilenamingSelector";

export default {
  components: {FilenamingSelector},
  computed: {
    ...mapState({
      assets: 'assets'
    })
  }
}
</script>