<template>
  <div class="dialog-content">
    <div>
      <label for="">
        {{ $t('language') }}
        <select
          class="user-input inverted"
          name=""
          id=""
          v-model="currentLocale"
        >
          <option
            v-for="(item) in auth.languages"
            :key="'header_editor_language_' + item"
            :value="item"
          >{{ item.toUpperCase() }}</option>
        </select>
      </label>
      <div class="title-input">
        <input
          type="text"
          class="user-input inverted"
          :placeholder="$t('title') + ' (' + currentLocale.toUpperCase() + ')'"
          v-model="properties.header_title[currentLocale]"
        >
      </div>
      <div class="vue-editor">
        <VueEditor
          v-model="properties.header_content[currentLocale]"
          :placeholder="$t('description') + ' (' + currentLocale.toUpperCase() + ')'"
          :key="'header_editor_' + currentLocale"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {VueEditor} from "vue2-editor/dist/vue2-editor.core.js";

export default {
  components: {VueEditor},
  data: function () {
    return {
      properties: {
        id: null,
        header_content: {
          de: '',
          en: '',
          fr: '',
          it: ''
        },
        header_title: {
          de: '',
          en: '',
          fr: '',
          it: ''
        }
      },
      currentLocale: this.$i18n.locale
    }
  },
  mounted: function () {
    this.properties = JSON.parse(JSON.stringify(this.dialog.properties.item));
    if(typeof this.properties.header_content === 'undefined' || this.properties.header_content === null || typeof this.properties.header_content === 'string'){
      this.properties.header_content = {};
    }
    if(typeof this.properties.header_title === 'undefined' || this.properties.header_title === null || Array.isArray(this.properties.header_title) || typeof this.properties.header_title === 'string'){
      this.properties.header_title = {};
    }
    if(typeof this.properties.preview === 'undefined'){
      this.properties.preview = null;
    }
  },
  watch: {
    properties: {
      handler: function () {
        this.$store.commit('dialogProperties', this.properties);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      auth: 'auth',
      dialog: 'dialog',
      assets: 'assets',
    })
  }
}
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>