<template>
  <div>
    {{ $t('helpcontent.sharing basics') }}

    {{ $t('helpcontent.sharing enabled for') }}
    <ul>
      <template v-for="(value, key) in client.features.share">
        <li
          :key="'share_type' + key"
        >
          {{ $t(key) }}: {{ $t((value ? 'true':'false')) }}
        </li>
      </template>
    </ul><br><div>
      {{ $t('helpcontent.sharing options basics') }}
    </div>
    <br>
    <div
      class="link clickable highlight no-padding"
      @click="openSharing"
    >
      {{ $t('open shared links') }}
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      client: 'client'
    })
  },
  methods: {
    openSharing () {
      this.$store.commit('help/helpState', false);
      this.$router.push('/shared-links');
    }
  }
}
</script>