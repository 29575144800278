<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('switch to manual') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      assets: 'assets'
    }),
  },
  mounted: function () {
    this.$store.dispatch('collections/index');
  },
  methods: {
    defaultAction: function () {

      this.assets.filters.sort.on = 'sort_index';
      this.assets.filters.sort.asc = true;
      this.closeDialog();
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>