<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('set reference') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      assetSelector: 'assetSelector'
    }),
  },
  methods: {
    defaultAction: function () {
      if (this.assetSelector.selection.length === 0) {
        alert(this.$t('no asset selected'));
        return;
      }
      var promises = [];


       promises.push(this.$store.dispatch(
            'assets/patch',
            {
              items: this.$store.state.assets.selection,
              properties: { 'reference_id': this.dialog.properties.reference }
            }
        ));

      Promise.allSettled(promises).then(() => {
        this.$store.dispatch('assets/load');
        this.$store.dispatch('assets/loadSingleAsset');
        this.$store.dispatch('hideDialog');
      });
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>