import axios from "axios";

export default {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    actions: {
        store: function (_, payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/links', payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        find: function(_, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/links/' + payload)
                  .then((response) => {
                      resolve(response);
                  })
                  .catch((error) => {
                      reject(error);
                  });
            });
        }
    },
    getters: {}
}