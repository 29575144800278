import axios from "axios";
import Vue from "vue";



export default {
    state: () => ({
        languages: ['de', 'en', 'fr', 'it'],
        user: {
            id: null,
            username: '',
            email: '',
            firstname: '',
            lastname: '',
            language: '',
            properties: [],
            modules: []
        },
        token: ""
    }),
    mutations: {
        login(state, payload) {
            state.user = payload.user;
            state.token = payload.token;
        },
        logout(state, payload) {
            state.user = payload.user;
            state.token = "";
        }
    },
    actions: {
        useLogin({commit}, payload) {
            commit('login', {
                user: payload.user,
                token: payload.token
            });
        },
        loginAsync({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('front/user/login', {
                    username: payload.username,
                    password: payload.password,
                }).then((response) => {

                    window.axios.defaults.headers = {'Authorization': 'Bearer ' + response.data.token, 'UID': response.data.user.id};

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user', response.data.user.id);

                    try {
                        if(window.opener !== null) {
                            window.opener.postMessage({token: localStorage.getItem('token'), uid: localStorage.getItem('user')}, '*');
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    commit('login', {
                        user: response.data.user,
                        token: response.data.token
                    });

                    if (typeof response.data.user.background !== 'undefined') {
                        document.documentElement.style.setProperty('--app-background-image', 'url("https://detail-brandhub.s3.eu-west-1.amazonaws.com/branding/colorthemes/' + response.data.user.background + '")');
                    }

                    if(typeof response.data.user.locale !== 'undefined'){
                        Vue.prototype.$bus.$emit('change-locale',response.data.user.locale);
                    }
                    resolve();
                }).catch((e) => {
                    reject(e)
                });
            });
        },
        logoutAsync({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                // Remove local storage token
                localStorage.removeItem('token');
                localStorage.removeItem('user');

                // Remove authorization Bearer token header
                window.axios.defaults.headers.common = {};

                // Commit to default user, license and token store
                commit('logout', {
                    user: {
                        id: null,
                        username: '',
                        email: '',
                        first_name: '',
                        last_name: '',
                        language: '',
                        properties: []
                    },
                    roles: {},
                    token: "",
                });
                commit('resetClient');
                dispatch('updateClient', null, {root: true})
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
            });
        },
    },
    getters: {
        roles: (state) => {
            return state.user.roles;
        },
        loggedIn: (state) => {
            return state.user.id !== null && state.token !== ""
        },
        displayName: (state) => {
            if (typeof state.user.firstname !== 'undefined' && typeof state.user.lastname !== 'undefined') {
                return state.user.firstname + ' ' + state.user.lastname;
            }

            if (typeof state.user.username !== 'undefined') {
                return state.user.username;
            }

            return state.user.email.substring(0, 10);
        }
    }
}