import axios from "axios";

export default {
  state: () => ({
    primary_queue: [],
    fresh_uploads: [],
    asset_replacement: [],
    options: {
      show_all_uploads: false
    }
  }),
  mutations: {
    addChunk(state, payload) {
      state[payload.queue].push(
        {
          files: [],
          uploadTotal: 0,
          uploadLoaded: 0,
          uploadProgress: 0,
          uploading: false,
          status: 'new'
        }
      )
      return state[payload.queue].length - 1;
    },
    files(state, payload) {
      state[payload.queue][payload.index].files = payload.files;
    },
    uploadTotal(state, payload) {
      state[payload.queue][payload.index].uploadTotal = payload.progress;
    },
    uploadLoaded(state, payload) {
      state[payload.queue][payload.index].uploadLoaded = payload.progress;
    },
    uploadProgress(state, payload) {
      state[payload.queue][payload.index].uploadProgress = payload.progress;
    },
    uploadFailed(state, payload) {
      state[payload.queue][payload.index].status = 'failed';
    },
    uploadActive(state, payload) {
      state[payload.queue][payload.index].uploading = payload.active;
    },
    removeFailed(state, payload) {
      var queue = state[payload.queue].filter((item) => item.status !== 'failed');
      state[payload.queue].length = 0;
      state[payload.queue] = queue;
    },
    freshUploads(state, payload) {
      state.fresh_uploads = payload;
    }
  },
  actions: {
    upload({commit, state, dispatch}, payload) {
      commit('addChunk', {queue: payload.queue, active: true});
      var indexChunk = state[payload.queue].length - 1;

      commit('uploadActive', {queue: payload.queue, status: 'new', active: true, index: indexChunk});
      commit('files', {queue: payload.queue, files: payload.files, index: indexChunk});

      var formData = new FormData();

      var started_uploads = [];

      payload.files.forEach((f) => {
        started_uploads.push(f.name);
        formData.append('file', f);
      });

      axios.post('api/assets/new', {queue: payload.queue, files: started_uploads})
        .then((response) => {
          var i = 0;
          if (typeof response.data[i].upload_url !== 'undefined') {
            axios.put(response.data[i].upload_url, formData.file,
              {
                transformRequest: [function (data, headers) {
                  delete headers.Authorization;
                  delete headers.UID;

                    if(response.data[i].headers) {
                        Object.entries(response.data[i].headers).forEach(([key, value]) => {
                            headers[key] = value;
                        });
                    }

                  return formData.get('file'); // required for binary upload
                }],
                onUploadProgress: (progressEvent) => {
                  var uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                  commit('uploadTotal', {queue: payload.queue, progress: progressEvent.total, index: indexChunk});
                  commit('uploadLoaded', {queue: payload.queue, progress: progressEvent.loaded, index: indexChunk});
                  commit('uploadProgress', {queue: payload.queue, progress: uploadProgress, index: indexChunk});
                }
              })
              .then(() => {
                commit('files', {queue: payload.queue, files: [], index: indexChunk});
                commit('uploadProgress', {queue: payload.queue, progress: 0, index: indexChunk});
                commit('uploadActive', {queue: payload.queue, active: false, index: indexChunk});
                axios.post('api/assets', {
                  id: response.data[i].id,
                  name: response.data[i].name,
                  purpose: 'original',
                }).then(() => {
                  dispatch('updateUploaded');
                });
              }).catch(() => {
              setTimeout(() => {
                commit('uploadFailed', {queue: payload.queue, active: false, index: indexChunk});
              }, 100);
            });
          } // modified on
        })
    },
    updateUploaded: function ({commit, state, rootState}) {

      var filters = [];
      filters.push({property: 'new', operator: 'exists'});
      filters.push({property: 'new', operator: '=', value: true});
      if (state.options.show_all_uploads !== true) {
        filters.push({property: 'created_by', operator: '=', value: rootState.auth.user.id});
      }
      axios.get('api/assets?page=1&page_size=1000&filter=' + JSON.stringify(filters))
        .then((response) => {
          // const assets = response.data.assets.map(v => ({...v, tagging: true}))
          commit('freshUploads', response.data.assets);
        });
    },
    freshUploads({commit}, payload) {
      commit('freshUploads', payload);
    },
    removeById({state, dispatch}, payload) {
      var uploads = state.fresh_uploads.filter((item) => {
        return (!payload.includes(item.id))
      });
      dispatch('freshUploads', uploads);
    }
  },
  getters: {
    freshUploads: function (state) {
      return state.fresh_uploads
    }
  }
}