import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        queue: [],
        isRunning: false,
        targetRunningState: true,
        errors: [],
        highCompatibilityMode: false,
        parallelProcesses: 4
    }),
    mutations: {
        addRenderError(state, payload) {
            var exists = state.errors.find((item) => {
                if (item.queueIndex === payload.queueIndex && item.assetIndex === payload.assetIndex) {
                    return item;
                }
            });
            if (typeof exists === 'undefined') {
                state.errors.push(payload);
            }
        },
        setParallelProceses(state, payload) {
            var value = Math.max(1, Math.min(100, parseInt(payload)));
            window.localStorage.setItem('parallelProcesses', value);
            state.parallelProcesses = value;
        },
        setHighCompatibilityMode(state, payload) {
            state.highCompatibilityMode = payload;
        },
        addRenderJob(state, payload) {
            state.queue.push(payload);
        },
        removeRenderJob(state, payload) {
            state.queue.splice(payload, 1);
        },
        setRunningState(state, payload) {
            state.isRunning = payload;
        },
        setTargetRunningState(state, payload) {
            state.targetRunningState = payload;
        },
        cancelRenderQueue(state) {
            state.isRunning = false;
            state.queue.splice(0, 10000);
        },
        updateQueueAssetGeneratedFilename(state, payload) {
            state.queue[payload.queueIndex].assets[payload.assetIndex].generatedFilename = payload.generatedFilename;
        },
        updateQueueAsset(state, payload) {
            if (payload.asset !== null) {
                state.queue[payload.queueIndex].assets[payload.assetIndex].asset = payload.asset;
            }

            if (payload.status !== null) {
                state.queue[payload.queueIndex].assets[payload.assetIndex].status = payload.status;
            }

            var min_status = 4;
            var max_status = 0;
            var has_errors = false;
            state.queue[payload.queueIndex].assets.forEach((asset) => {
                max_status = Math.max(asset.status, min_status);
                min_status = Math.min(asset.status, min_status);
                if (asset.status === 3) {
                    has_errors = true;
                }
            });

            if (max_status > 1) {
                min_status = Math.max(min_status, 1);
            }

            if (has_errors) {
                min_status = 2;
            }

            var status = Math.min(min_status, 3);
            state.queue[payload.queueIndex].status = status;
            if (status === 3) {
                state.queue[payload.queueIndex].date_completed = new Date().toISOString();
            }
        }
    },
    actions: {
        spawnProcesses({commit, state, dispatch}) {
            if (state.isRunning) {
                return;
            }
            commit('setRunningState', true);
            commit('setTargetRunningState', true);

            for (var i = 0; i < state.parallelProcesses; i++) {
                dispatch('render');
            }
        },
        pauseRendering({commit}) {
            commit('setTargetRunningState', false);
        },
        render({commit, getters, state, dispatch}) {
            return new Promise((resolve) => {
                var nextQueueAsset = getters['nextQueueAsset'];
                if (nextQueueAsset === null) {
                    commit('setRunningState', false);
                    resolve();
                }

                commit('updateQueueAsset', {
                    queueIndex: nextQueueAsset.queueIndex,
                    assetIndex: nextQueueAsset.assetIndex,
                    asset: null,
                    status: 4
                });

                dispatch('fetchQueueAsset', nextQueueAsset)
                    .then((data) => {

                        commit('updateQueueAsset', {
                            queueIndex: nextQueueAsset.queueIndex,
                            assetIndex: nextQueueAsset.assetIndex,
                            asset: data,
                            status: 2
                        });

                        if (!data.mime_type.includes('image')) {
                            // Error, only support images currently via CDN
                            commit('updateQueueAsset', {
                                queueIndex: nextQueueAsset.queueIndex,
                                assetIndex: nextQueueAsset.assetIndex,
                                asset: null,
                                status: 3
                            });
                            commit('addRenderError', {
                                queueIndex: nextQueueAsset.queueIndex,
                                assetIndex: nextQueueAsset.assetIndex,
                                cause: 'filetype not supported'
                            });
                            dispatch('render');
                            return;
                        }
                        var cdn_url = data.cdn_url;
                        var index = nextQueueAsset.assetIndex;
                        var settings = nextQueueAsset.queue.settings.image;

                        /*
                         * Generate Filename
                         */
                        var filenameSettings = nextQueueAsset.queue.settings.naming;
                        var filename = '';

                        filenameSettings.forEach((namingRule) => {
                            if (namingRule.type === 'original') {
                                filename += nextQueueAsset.asset.asset.name.substring(0, nextQueueAsset.asset.asset.name.length - nextQueueAsset.asset.asset.extension.length - 1);
                            }
                            if (namingRule.type === 'text') {
                                filename += namingRule.text;
                            }
                            if (namingRule.type === 'sequential_number') {
                                filename += (index + parseInt(namingRule.startnumber)).toString().padStart(namingRule.digits, '0');
                            }
                            if (namingRule.type === 'extension_lowercase') {
                                filename += '.' + settings.format.toLowerCase();
                            }
                            if (namingRule.type === 'extension_uppercase') {
                                filename += '.' + settings.format.toUpperCase();
                            }

                        });


                        // Prevent empty filenaming rules
                        if (filename === '') {
                            commit('updateQueueAsset', {
                                queueIndex: nextQueueAsset.queueIndex,
                                assetIndex: nextQueueAsset.assetIndex,
                                asset: null,
                                status: 3
                            });
                            commit('addRenderError', {
                                queueIndex: nextQueueAsset.queueIndex,
                                assetIndex: nextQueueAsset.assetIndex,
                                cause: 'target filename invalid'
                            });
                            dispatch('render');
                            return;
                        }

                        commit('updateQueueAssetGeneratedFilename', {
                            queueIndex: nextQueueAsset.queueIndex,
                            assetIndex: nextQueueAsset.assetIndex,
                            generatedFilename: filename
                        });


                        /*
                         * Generate CDN Query URL
                         */
                        var urlparams = {
                            width: settings.width,
                            height: settings.height,
                            format: settings.format
                        }
                        if (settings.format === 'jpg') {
                            urlparams.quality = settings.quality;
                            urlparams.progressive = settings.progressive;
                        }
                        if (settings.format === 'png' || settings.format === 'tif') {
                            urlparams.transparency = settings.transparency;
                        }
                        var querystring = '?fm=' + urlparams.format + '&fit=fit';
                        if(settings.sizing === 'resize'){
                            querystring += 'w=' + urlparams.width + '&h=' + urlparams.height;
                        }

                        if (typeof urlparams.quality != 'undefined') {
                            querystring += '&q=' + urlparams.quality;
                        }

                        if (typeof urlparams.quality != 'undefined') {
                            querystring += '&q=' + urlparams.quality;
                        }


                        querystring += '&version=download&dl=' + filename;
                        if (state.targetRunningState === true) {
                            if (state.highCompatibilityMode === true) {
                                // opens a new tab or browser window for high compatibility
                                window.open(cdn_url + querystring);
                                window.focus();
                            } else {
                                // Allows sequential download in hidden iframe
                                window.open(cdn_url + querystring, "downloadframe_" + nextQueueAsset.queueIndex + "_" + nextQueueAsset.assetIndex);
                            }
                            setTimeout(()=>{
                            commit('updateQueueAsset', {
                                queueIndex: nextQueueAsset.queueIndex,
                                assetIndex: nextQueueAsset.assetIndex,
                                asset: null,
                                status: 4
                            });
                            }, 300);
                        } else {
                            // Reset asset export status in case of pause / abort
                            if(nextQueueAsset.asset.status !== 4) {
                                commit('updateQueueAsset', {
                                    queueIndex: nextQueueAsset.queueIndex,
                                    assetIndex: nextQueueAsset.assetIndex,
                                    asset: null,
                                    status: 0
                                });
                            }
                        }
                        dispatch('render')
                            .then(() => {
                            });
                    })
                    .catch(() => {
                        commit('updateQueueAsset', {
                            queueIndex: nextQueueAsset.queueIndex,
                            assetIndex: nextQueueAsset.assetIndex,
                            asset: null,
                            status: 3
                        });

                        commit('addRenderError', {
                            queueIndex: nextQueueAsset.queueIndex,
                            assetIndex: nextQueueAsset.assetIndex,
                            cause: 'general processing error'
                        });
                        dispatch('render');
                    });

            });
        },
        fetchQueueAsset(_, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/assets/' + payload.asset.asset.id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch(() => {
                        reject();
                    })
            });
        },
        addRenderJob({commit, dispatch, state, rootState}, payload) {

            window.localStorage.setItem('exportSettings', JSON.stringify(payload.exportSettings));

            var job = {
                status: 0, // 0 = waiting, 1 = processing, 2 = error, 3 = done
                settings: payload.exportSettings,
                expanded: true,
                date: new Date().toISOString(),
                date_completed: null,
                assets: []
            };

            payload.items.forEach((item) => {

                var cachedAsset = rootState.assets.items.find((rootAsset) => {
                    return rootAsset.id === item;
                });

                var asset = {
                    asset: cachedAsset,
                    status: 0, // 0 = waiting, 1 = fetching, 2 = processing, 3 = error, 4 = done
                    generatedFilename: null
                }
                job.assets.push(asset)
            });

            commit('addRenderJob', job);
            if (state.targetRunningState === true) {
                dispatch('spawnProcesses');
            }

        },
        removeRenderJob({commit}, payload) {
            commit('removeRenderJob', payload);
        }
    },
    getters: {
        renderWorker(state) {
            return state.isRunning;
        },
        queueProgress(state) {
            return state.queue.length;
        },
        nextQueueAsset(state) {
            var nextAsset = null;
            if (state.targetRunningState === false) {
                return null;
            }
            state.queue.forEach((queue, queueIndex) => {
                queue.assets.forEach((asset, assetIndex) => {
                    if (asset.status === 0) {
                        nextAsset = {queue: queue, queueIndex: queueIndex, asset: asset, assetIndex: assetIndex};
                    }
                })
            });
            return nextAsset;
        },
        queue(state) {
            return state.queue;
        }
    }
}
