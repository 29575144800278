<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('save') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client'
    })
  },
  methods: {
    defaultAction: function () {


      var dispatcher = 'tags/update';
      if (typeof this.dialog.properties.tag.id === 'undefined') {
        dispatcher = 'tags/store'
      }

      var dialogtag = this.dialog.properties.tag;

      var tagmodel = {
        id: dialogtag.id,
        name: dialogtag.name,
      };


      this.$store.dispatch(dispatcher, {tag: tagmodel}).then(() => {
        this.$store.commit('addNotification',
            {
              title: 'tag updated',
              class: 'highlight',
              data: {},
              datetime: new Date()
            }
        );
        this.$store.dispatch('tags/index').then(() => {
          this.closeDialog();
        });

      }).catch((error) => {
        this.$store.commit('addNotification',
            {
              title: 'saving tag failed',
              class: 'error',
              description: error.response.data.error,
              datetime: new Date()
            }
        );
      });
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>