<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('ok') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      collections: 'collections',
      basket: 'basket'
    }),
  },
  mounted: function () {
    this.$store.dispatch('basket/refresh');
  },
  methods: {
    defaultAction: function () {
      this.closeDialog();
      var basketAssetIds =  [];
      this.basket.basket.assets.forEach((asset) => {
        basketAssetIds.push(asset.id);
      });
      if (this.dialog.properties.new_collection_title !== '') {
        this.$store.dispatch('collections/store', { name: this.dialog.properties.new_collection_title, parent_id: null })
        .then((response) => {
          axios.put('api/asset-collections/' + response.id + '/assets/' + basketAssetIds.join(','))
              .then(() => {
                this.$store.commit('addNotification',
                    {
                      title: 'collection created from basket assets',
                      class: 'highlight',
                      data: {
                        count: response.data.assets.length,
                        name: response.data.collection.name
                      },
                      datetime: new Date()
                    })
              })
        })
      } else {
        axios.put('api/asset-collections/' + this.dialog.properties.collection + '/assets/' + basketAssetIds.join(','))
            .then((response) => {
              this.$store.commit('addNotification',
                  {
                    title: 'assets moved from basket to collection',
                    class: 'highlight',
                    data: {
                      count: response.data.assets.length,
                      name: response.data.collection.name
                    },
                    datetime: new Date()
                  })
            })
      }
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>