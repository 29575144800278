<template>
  <div>
    <div
      class="help-topic-title"
      v-if="help.item !== ''"
    >
      <div
        class="help-topic-primary"
        v-if="help.topic !== ''"
      >
        {{ $t(help.topic) }}
      </div>
      <div
        class="help-topic-secondary"
        v-if="help.item !== ''"
      >
        {{ $t(help.item) }}
      </div>
    </div>
    <template v-if="typeof topic !== 'undefined'">
      <component
        :is="topic.component"
        v-if="topic.component !== null"
        :auth="auth"
        :client="client"
        :help="help"
      />
      <div v-if="topic.content !== null">
        {{ $t(topic.content) }}
      </div>
    </template>
    <template v-else>
      {{ $t('helpcontent.choose a topic on the left') }}
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";
import UserRegister from "@/components/layout/help/components/UserRegister";
import UserActivate from "@/components/layout/help/components/UserActivate";
import UserManagement from "@/components/layout/help/components/UserManagement";
import AssetExport from "@/components/layout/help/components/AssetExport";
import AssetCollectionShare from "@/components/layout/help/components/AssetCollectionShare";
import AssetThumbnailReplacement from "@/components/layout/help/components/AssetThumbnailReplacement";
import AssetUpload from "@/components/layout/help/components/AssetUpload";
import AssetDownload from "@/components/layout/help/components/AssetDownload";
import AssetDerivatives from "@/components/layout/help/components/AssetDerivatives";

export default {
  components: {UserRegister, UserActivate, UserManagement, AssetExport, AssetCollectionShare, AssetThumbnailReplacement, AssetUpload, AssetDownload, AssetDerivatives},
  computed: {
    ...mapState({
      auth: 'auth',
      client: 'client',
      help: 'help',
    }),
    topic: function () {
      if (this.help.topic === '') {
        return undefined
      }
      return this.help.topics[this.help.topic].find((item) => {
        if (item.title === this.help.item) {
          return item;
        }
      })
    }
  }
}
</script>