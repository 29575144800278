<template>
  <div class="dialog-content">
    <template v-if="!loaded">
      <div
        class="loader active"
      >
        <div class="loaderBar" />
      </div>
    </template>
    <template v-else-if="loaded && derivatives.length > 0">
      <table class="table inverted full-width">
        <tr>
          <td>{{ $t('name') }}</td>
          <td>{{ $t('id') }}</td>
          <td>{{ $t('language') }}</td>
          <td class="right">
            {{ $t('unlink derivative') }}
          </td>
        </tr>
        <tr
          :key="item.id"
          v-for="(item) in derivatives"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.id }}</td>
          <td>
            <template v-if="['de','en','fr','it'].includes(item.language)">
              {{ $t('language_' + item.language) }}
            </template>
            <template v-else>
              {{ $t('unknown') }}
            </template>
          </td>
          <td class="right">
            <div
              @click="unlinkDerivative(item.id)"
              :title="$t('unlink derivative')"
            >
              <div class="clickable   icon-derivate-unlink error icon-large" />
            </div>
          </td>
        </tr>
      </table>
    </template>
    <div v-else>
      {{ $t('no linked derivatives') }}
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  components: {},
  data: function () {
    return {
      derivatives: [],
      loaded: false
    }
  },
  mounted: function () {
    this.properties = this.dialog.properties;
    this.loadDerivatives();
  },
  methods: {
    loadDerivatives() {
      var filter = [
        {
          property: 'reference_id',
          operator: '=',
          value: this.properties.item
        }
      ];
      axios.get('api/assets?filter=' + JSON.stringify(filter))
          .then((response) => {
            this.derivatives = response.data.assets;
            this.loaded = true;
          })


    },
    unlinkDerivative(item) {
      var confirmation = confirm(this.$t('unlink derivative question'));
      if (!confirmation) {
        return;
      }

      this.loaded = false;
      this.$store.dispatch(
          'assets/patch',
          {
            items: [item],
            properties: {'reference_id': null}
          }
      ).then(() => {
        this.$store.commit('addNotification',
            {
              title: 'link disconnected',
              description: '',
              class: 'success',
              datetime: new Date()
            }
        );
        this.loadDerivatives();
      })
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  },
  watch: {
    properties: {
      handler: function () {
        if (this.loaded === true) {
          this.$store.commit('dialogProperties', this.properties);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
    }),
  }
}
</script>