import de from './translations/de';
import en from './translations/en';
import fr from './translations/fr';
import it from './translations/it';

export default {
    de,
    en,
    fr,
    it
};
