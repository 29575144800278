<template>
  <div>
    <template v-for="(role) in sortedModules">
      <div :key="'role_' + role">
        <label
          :key="'role_checkbox_' + role"
          class="link no-padding clickable"
        ><input
          v-model="localValue"
          class="user-input checkbox"
          type="checkbox"
          :value="role"
        >&nbsp; {{
          $t(role)
        }}</label>
      </div>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      mounted: false,
      localValue: this.value
    }
  },
  watch: {
    localValue: {
      handler: function () {
          this.$emit('input', this.localValue);
      },
      deep: true
    }
  },
  mounted: function () {
    this.localValue = this.value;
    this.mounted = true;
  },
  computed: {
    ...mapState({
      client: 'client',
    }),
    sortedModules() {
      return JSON.parse(JSON.stringify(this.client.modules)).sort();
    }
  }
}
</script>