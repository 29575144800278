import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    await_access: true,
    access_code: null,
    assets: [],
    redirect: null,
    error: false,
    properties: {},
    selection: [],
    fullscreen: false
  }),
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAwaitAccess(state, payload) {
      state.await_access = payload;
    },
    setAccessCode(state, payload) {
      state.access_code = payload;
    },
    setRedirect(state, payload) {
      state.redirect = payload;
    },
    setFullscreen(state, payload) {
      state.fullscreen = payload;
    },
    setAssets(state, payload) {
      state.assets = payload.assets;
    },
    setProperties(state, payload) {
      state.properties = payload;
    },
    select(state, payload) {
      // Handle Ctrl + Click to add or subtract from selection
      if (payload.modifier === true) {
        var index = state.selection.indexOf(payload.asset)
        if (index >= 0) {
          state.selection.splice(index, 1);
        } else {
          state.selection.push(payload.asset);
        }
        return;
      }

      // Replace entire selection if no modifier is supplied
      state.selection = [payload.asset];
    }
  },
  actions: {
    selection({commit}, payload) {
      commit('select', payload);
    },
    load({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true);

        axios.get('front/lightbox/' + payload.id, {headers: {"Access-Code": state.access_code}})
          .then((response) => {
            commit('setProperties', response.data.link);
            commit('setAssets', {assets: response.data.collection.assets});
            commit('setLoading', false);
            commit('setAwaitAccess', false);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response.code === 403) {
              commit('setAwaitAccess', true);
            }

            if (error.response.status === 401) {
              commit('setRedirect', payload.id);
            }
            commit('setLoading', false);
            reject(error.response.status);
          });
      })
    }
  },
  getters: {
    selectionIndex: (state, getters) => {
      return getters.sortedAssets.map(e => e.id).indexOf(state.selection[0]);
    },
    sortedAssets: (state) => {
      var sorting = 'name.asc';
      if (typeof state.properties.sorting !== 'undefined') {
        sorting = state.properties.sorting;
      }

      var sorting_properties = sorting.split('.');
      var sorting_property = sorting_properties[0];
      var sorting_direction= sorting_properties[1] === 'asc' ? 1 : -1;

      return state.assets.sort(function (a, b) {
          if (typeof a[sorting_property] === 'string') {
            return sorting_direction * a[sorting_property].localeCompare(b[sorting_property]);
          }

          return sorting_direction * (a[sorting_property] > b[sorting_property] ? 1 : -1);
      });
    },
    nextItem: (state, getters) => {
      var selection;
      if (getters.selectionIndex + 1 >= getters.sortedAssets.length) {
        selection = getters.sortedAssets[0].id;
      } else {
        selection = getters.sortedAssets[getters.selectionIndex + 1].id;
      }
      return selection;
    },
    previousItem: (state, getters) => {
      var selection;
      if (getters.selectionIndex <= 0) {
        selection = getters.sortedAssets[getters.sortedAssets.length - 1].id;
      } else {
        selection = getters['sortedAssets'][getters['selectionIndex'] - 1].id;
      }
      return selection;
    }
  }
}
