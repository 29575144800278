import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        items: [],
        selected: [],
        search: '',
        page: 1,
        max_page: 1,
        sortby: 'email',
        sortasc: true,
        dialog: false,
    }),
    mutations: {
        users: function (state, payload) {
            state.items = payload;
        },
        maxPage: function(state, payload) {
            state.max_page = payload;
        }
    },
    actions: {
        search({dispatch, state}, payload) {
            state.search = payload.search;
            state.page = 1;
            dispatch('index', {root: true});
        },
        page({dispatch, state}, payload) {
            state.page = payload.page;
            dispatch('index', {root: true});
        },
        sortBy({dispatch, state}, payload) {
            state.sortby = payload.sortby;
            state.sortasc = payload.sortasc;
            state.page = 1;
            dispatch('index', {root: true});
        },
        store(_, payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/users',
                    payload.user
                )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        update(_, payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/users/' + payload.user.id,
                    payload.user
                )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        index({commit, state}) {
            return new Promise((resolve, reject) => {

                var query = '?page=' + state.page + '&page_size=1000&sort=[{"property":"' + state.sortby + '","direction":"' + (state.sortasc ? "asc" : "desc") + '"}]';
                if (state.search !== '') {
                    query += '&filter=[{"property":"username","operator":"like","value":"' + state.search + '"}]';
                }

                axios.get('api/users' + query)
                    .then((response) => {
                        commit('users', response.data.users);
                        commit('maxPage', response.data.page_count);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        show(_, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/users/' + payload.id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        destroy(_, payload) {
            return new Promise((resolve, reject) => {
                axios.delete('api/users/' + payload.id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

    },
    getters: {}
}