<template>
  <div>
    {{ $t('helpcontent.asset upload basics') }}
    <br>
    <h2>{{ $t('helpcontent.upload process') }}</h2><br>

    <div class="flowchart">
      <div class="flowchart-item terminator">
        {{ $t('upload') }}
      </div>
      <div class="flowchart-connector" />
      <div
        class="flowchart-item process"
        tabindex="0"
      >
        <div class="flowchart-icon icon-type_image" />
        {{ $t('generate thumbnail') }}
        <div class="flowchart-description">
          {{ $t('helpcontent.generate thumbnail') }}
          <br><br>
          <table class="listing">
            <tr>
              <td>
                <div class="icon-database icon-xlarge" />
              </td>
              <td>{{ $t('image purpose new') }}</td>
            </tr>
            <tr>
              <td>
                <div class="icon-database pulsating highlight icon-xlarge" />
              </td>
              <td>{{ $t('image purpose processing') }}</td>
            </tr>
            <tr>
              <td>
                <div class="icon-database_warning warn icon-xlarge" />
              </td>
              <td>{{ $t('image purpose failed') }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="flowchart-connector" />

      <div
        class="flowchart-item process"
        tabindex="0"
      >
        <div class="flowchart-icon icon-metadata" />
        {{ $t('process metadata') }}
        <div class="flowchart-description">
          {{ $t('helpcontent.process metadata') }}
        </div>
      </div>
      <div class="flowchart-connector" />
      <div class="flowchart-item terminator">
        {{ $t('process finish') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {}
  }
}
</script>