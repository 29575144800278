<template>
  <div
    class="app"
    @mousemove="mousemove($event)"
    @mouseup="mouseup()"
  >
    <Help />
    <link
      rel="stylesheet"
      type="text/css"
      :href="'https://detail-brandhub.frb.io/front/branding/style.css?domain=' + client.domain"
    >
    <Dialog v-if="dialog.show" />
    <Notification />
    <template v-if="(!loggedin && $store.state.auth.token == null) || allowed_unauthenticated_routes.includes(routeName)">
      <router-view
        :config="config"
      />
    </template>

    <template v-else-if="loggedin && !allowed_unauthenticated_routes.includes(routeName) ">
      <template v-if="$store.state.auth.user.modules.includes('application')">
        <Navbar :config="config" />
      </template>
      <router-view
        :show-properties="true"
        :is-maximized="true"
        :config="config"
      />
    </template>
  </div>
</template>
<script>
import axios from "axios";
import Navbar from './components/layout/Navbar';
import Dialog from './components/helpers/Dialog';
import Notification from './components/helpers/Notification';
import Help from './components/layout/help/Index';

import {mapState} from "vuex";
import Vue from "vue";

export default {
  name: 'App',
  components: {
    Navbar,
    Dialog,
    Notification,
    Help
  },
  data() {
    return {
      keepaliveInterval: null,
      allowed_unauthenticated_routes: ['login', 'home', 'register', 'public-de', 'public-fr', 'public-it', 'public-en', 'register_success', 'public', 'forgot_password', 'lightbox', 'forgot_password_requested', 'reset_password', 'lightbox', 'set_password'],
      config: {
        layout: {
          menu: {
            active: false
          },
          assets: {
            sidebar: {
              active: true,
              width: (typeof localStorage.getItem('presets.assets.sidebar.width') != 'undefined' && localStorage.getItem('presets.assets.sidebar.width') != null ? parseInt(localStorage.getItem('presets.assets.sidebar.width')) : 350), // pixel width
              is_resizing: false,
              resize_handler_active: false,
              active_tab: 0,
              items: [
                {
                  title: 'browse',
                  icon: 'search',
                  component: 'Folders'
                },
                {
                  title: 'basket',
                  icon: 'metadata',
                  component: 'Basket'

                },
                {
                  title: 'share',
                  icon: 'share',
                  component: 'Share'
                }
              ]
            },
            transfers: {
              active_transfer_type: 'upload'
            },
            folders: {
              active_collection: 0,
              collectionsmenu: [
                {
                  name: "folders",
                  collection: "folders",
                  component: "Foldertree"
                },
                {
                  name: "collections",
                  collection: "collections",
                  component: "Collections"
                },
                {
                  name: "keywords",
                  collection: "keywords",
                  component: "Keywords"
                }

              ]
            }
          }
        },
        selection: []
      },
    }
  },
  computed: {
    ...mapState({
      auth: 'auth',
      client: 'client',
      dialog: 'dialog',
      assets: 'assets',
    }),
    loggedin: function () {
      return this.$store.state.auth.token !== '';
    },
    routeName() {
      return this.$route.name
    },
  },
  beforeMount: function () {
  },
  beforeDestroy() {
    clearInterval(this.keepaliveInterval);
  },
  mounted: function () {
    this.$store.dispatch("updateClient")
        .then(() => {
          if(typeof this.client.features.assets !== 'undefined' && typeof this.client.features.assets.collectionsmenu !== 'undefined'){
            this.config.layout.assets.folders.collectionsmenu = this.client.features.assets.collectionsmenu;
          }
          if(typeof this.client.features.assets !== 'undefined' && typeof this.client.features.assets.assettype !== 'undefined'){
            this.$store.dispatch('assets/type', {type: this.client.features.assets.assettype});
          }
        })

    this.$router.onReady(() => {
      this.$watch( ()=> this.$route.path,(to, from)=> {
        this.sessionElgibility();
      });

      this.sessionElgibility();
      this.setLocale();
      this.autologin();
    });
    this.keepaliveInterval = setInterval(() => {
      this.autologin();
    }, 3600000);

    var theme_list = ['auto', 'dark', 'light'];
    var theme = localStorage.getItem('theme');
    if (theme === null || !theme_list.includes(theme)) {
      theme = 'dark';
    }

    localStorage.setItem('theme', theme);
    var root = document.getElementsByTagName('html')[0];
    root.className = '';
    root.classList.add(theme);

    this.$store.dispatch('ui/retrieve');
  },
  methods: {
    sessionElgibility: function () {
      if ([
        "register",
        "forgot_password",
        "register_success",
        "forgot_password_requested",
        "reset_password",
        "set_password"
      ].includes(this.$route.name)) {
        window.localStorage.clear('user');
        window.localStorage.clear('token');
        window.localStorage.clear('theme');
      }
    },
    setLocale: function () {
      if (typeof this.$route.query.locale === 'string' && ['de', 'fr', 'en', 'it'].includes(this.$route.query.locale)) {
        this.$i18n.locale = this.$route.query.locale;
      }
    },
    autologin: function () {

      if (localStorage.getItem('user') !== null && localStorage.getItem('token') !== null) {
        window.axios.defaults.headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'UID': localStorage.getItem('user')
        }

        axios.get('front/user/keepalive')
            .then((response) => {
              if (typeof response.data.user.background !== 'undefined') {
                document.documentElement.style.setProperty('--app-background-image', 'url("https://detail-brandhub.s3.eu-west-1.amazonaws.com/branding/colorthemes/' + response.data.user.background + '")');
              }

              this.$store.dispatch("updateClient", {dispatch: {login: response.data}});

              try {
                if (window.opener !== null) {
                  window.opener.postMessage({token: localStorage.getItem('token'), uid: localStorage.getItem('user')}, '*');
                }
              } catch (e) {
                console.log(e);
              }

              if (typeof response.data.user.locale !== 'undefined') {
                Vue.prototype.$bus.$emit('change-locale', response.data.user.locale);
              }

              if (this.$router.currentRoute.name === 'public') {
                if (typeof this.$store.state.client.public_page === 'undefined' || (typeof this.$store.state.client.public_page !== 'undefined' && this.$store.state.client.public_page.active === false)) {
                  this.$router.push('/login');
                }
              }
            })
            .catch(() => {
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              window.axios.defaults.headers = {};
              this.$store.dispatch("updateClient")
                  .then(() => {
                    if (typeof this.$store.state.client.public_page === 'undefined' || (typeof this.$store.state.client.public_page !== 'undefined' && this.$store.state.client.public_page.active === false)) {
                      this.$router.push('/login');
                    }
                  });
            });
      } else {
        this.$store.dispatch("updateClient")
            .then(() => {
              if (this.$router.currentRoute.name === 'public') {
                if (typeof this.$store.state.client.public_page === 'undefined' || (typeof this.$store.state.client.public_page !== 'undefined' && this.$store.state.client.public_page.active === false)) {
                  this.$router.push('/login');
                }
              }
            })
        if (!this.allowed_unauthenticated_routes.includes(this.routeName)) {
          this.$router.push('/');
          this.$store.dispatch("updateClient");
        } else {
          this.$store.dispatch("updateClient");
        }
      }
    },
    mouseup: function () {
      this.$store.dispatch('ui/mouseUp');
      this.$store.dispatch('ui/persist');
      this.config.layout.assets.sidebar.is_resizing = false;
      if (this.$store.getters.isMouseDown === true) {
        this.$store.commit('mouse', false);
        this.$store.commit('drag', false);
      }
    },
    mousemove: function (event) {
      this.$store.dispatch('ui/mouseMove', event);

      if (this.config.layout.assets.sidebar.is_resizing) {
        // Resize the assets sidebar and persist width in local storage
        this.config.layout.assets.sidebar.width = Math.max(this.config.layout.assets.sidebar.width + (event.movementX * 3), 350);
        localStorage.setItem('presets.assets.sidebar.width', this.config.layout.assets.sidebar.width);
      } else {
        if (this.$store.getters.isMouseDown === true) {
          this.$store.commit('cursorLocation', {x: event.clientX, y: event.clientY});
          this.$store.dispatch('setDrag', true);
        }
      }
    }
  }
}
</script>
<style lang="scss">

@import '@/sass/app.scss';

</style>