// Imports
import Vue from 'vue'
import VueRouter from 'vue-router'

// Assign router
Vue.use(VueRouter);

// Routes
const routes = [
  {
    path: '/front',
    name: 'front',
    component: () => import('../components/layout/front/FrontPage'),
  },
  {
    path: '/',
    name: 'public',
    component: () => import('../components/public/Landing'),
    children: []
  }, {

    path: '/de',
    name: 'public-de',
    meta: {
      locale: 'de'
    },
    component: () => import('../components/public/Landing')
  },
  {

    path: '/en',
    name: 'public-en',
    meta: {
      locale: 'en'
    },
    component: () => import('../components/public/Landing')
  },
  {

    path: '/fr',
    name: 'public-fr',
    meta: {
      locale: 'fr'
    },
    component: () => import('../components/public/Landing')
  },
  {

    path: '/it',
    name: 'public-it', meta: {
      locale: 'it'
    },
    component: () => import('../components/public/Landing')
  },
  {

    path: '/',
    name: 'public',
    component: () => import('../components/Landing'),
    children: [

      {

        path: '/login',
        name: 'login',
        component: () => import('../components/user/Login')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../components/user/Register')
      },
      {
        path: '/register_success',
        name: 'register_success',
        component: () => import('../components/user/RegisterSuccess')
      },
      {
        path: '/forgot_password',
        name: 'forgot_password',
        component: () => import('../components/user/ForgotPassword')
      },
      {
        path: '/forgot_password_requested',
        name: 'forgot_password_requested',
        component: () => import('../components/user/ForgotPasswordRequested')
      },
      {
        path: '/reset-password/:token',
        name: 'reset_password',
        component: () => import('../components/user/ResetPassword')
      },
      {
        path: '/set-password/:token',
        name: 'set_password',
        component: () => import('../components/user/SetPassword')
      }
    ]
  },
  {
    path: '/assets',
    name: 'asset-management',
    component: () => import('../components/layout/assets/Assets')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/user/Profile')
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import('../components/layout/keywords/Index')
  },
  {
    path: '/shared-links',
    name: 'shared-links',
    component: () => import('../components/layout/assets/links/Links')
  },
  {
    path: '/uploads',
    name: 'upload-queue',
    component: () => import('../components/layout/assets/transfers/Upload')
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('../components/layout/assets/sidebar/Export')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../components/layout/settings/Settings')
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('../components/layout/client/Index'),
    children: [
      {
        path: '/',
        name: 'client.configuration',
        component: () => import('../components/layout/client/Configuration')
      },
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../components/layout/statistics/Statistics')
  },
  {
    path: '/public_page',
    name: 'public_page',
    component: () => import('../components/layout/client/PublicPage'),
    children: []
  },
  {
    path: '/collaboration',
    name: 'collaboration',
    component: () => import('../components/layout/collaboration/Index')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../components/layout/users/Index')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('../components/layout/documents/Documents.vue'),
    children: [
      {
        path: '/',
        name: 'documents.index',
        component: () => import('../components/layout/documents/DocumentsIndex.vue')
      },
      {
        path: ':post_type',
        name: 'documents.post_type',
        component: () => import('../components/layout/documents/Listing.vue')
      }
    ]
  },
  {
    path: '/external',
    name: 'external',
    component: () => import('../components/layout/external/ExternalFrame.vue'),
    children: [
      {
        path: ':service',
        name: 'externalservice',
        component: () => import('../components/helpers/ExternalUrl.vue')
      }
    ]
  },
  {
    path: "*",
    component: () => import('../components/defaults/NotFound'),
    name: 'notfound',
    meta: {
      auth: true
    }
  },
  {
    path: '/lightbox/:link_id',
    name: 'lightbox',
    component: () => import('../components/layout/lightbox/Lightbox')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;