<template>
  <div class="dialog-content">
    <div class="form-structure">
      <div class="avatar-info">
        <div class="user-avatar">
          <img
            alt=""
            src=""
          >
        </div>
        <div class="user-avatar-metadata">
          <div class="user-avatar-company">
            {{ client.company }}
          </div>
          <div class="user-avatar-name">
            {{ user.username }}
          </div>
        </div>
      </div>

      <div class="line-separator inverted " />

      <div class="subtitle">
        {{ $t('properties') }}
      </div>




      <div class="input-group">
        <div class="input-label">
          {{ $t('created_on') }}
        </div>
        <div class="input-value disabled text-only">
          {{ $date(user.created_on).format('DD.MM.YYYY, HH:mm') }}
        </div>
      </div>


      <div class="input-group">
        <div class="input-label">
          {{ $t('email') }}
        </div>
        <div class="input-value">
          <input
            :disabled="!creating"
            class="user-input inverted"
            type="text"
            v-model="user.email"
          >
        </div>
      </div>

      <div class="input-group">
        <div class="input-label">
          {{ $t('username') }}
        </div>
        <div class="input-value">
          <input
            :disabled="!creating"
            class="user-input inverted"
            type="text"
            v-model="user.username"
          >
        </div>
      </div>


      <div class="input-group">
        <div class="input-label">
          {{ $t('active') }}
        </div>
        <div class="input-value">
          <input
            class="user-input checkbox inverted"
            type="checkbox"
            v-model="user.active"
          >
          <template v-if="typeof user.id !== 'undefined' && activeState !== user.active && setPassword == false">
            <div>
              <template v-if="user.active">
                <div class="inline-notice inline-list">
                  <div class="icon-exclamation" />
                  {{ $t('warning user active switch') }}
                </div>
              </template>
              <template v-else>
                <div class="inline-notice inline-list">
                  <div class="icon-exclamation" />
                  {{ $t('warning user inactive switch') }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <!-- TODO: Select if the password will be autogenerated and changed by the user or set manually in this dialog -->
      <template v-if="user.active">
        <div class="input-group">
          <div class="input-label">
            {{ $t('set password manually') }}
          </div>
          <div class="input-value">
            <input
              class="user-input checkbox inverted"
              type="checkbox"
              v-model="setPassword"
            >
            <template v-if="setPassword">
              <div class="inline-notice inline-list">
                <template v-if="user.active && this.modelState === false && setPassword === true">
                <div class="icon-exclamation " />
                {{ $t('warning user receives password via email') }}
                </template>
              </div>
              <input
                :placeholder="$t('password')"
                class="user-input inverted"
                type="text"
                v-model="user.password"
              >
            </template>
          </div>
        </div>

        <PasswordStrength
          :password="user.password"
          :userdata="user"
        />
      </template>

      <div class="line-separator inverted " />

      <div class="input-group">
        <div class="input-label">
          {{ $t('roles') }}
        </div>
        <div class="input-value">
          <template v-for="(roleitem) in roles">
            <label :key="'edit_roles_' + roleitem.id">
              <input
                type="checkbox"
                :value="roleitem.id"
                v-model="user.roles"
              > {{ roleitem.title[$i18n.locale] }}<br>
            </label>
          </template>
        </div>
      </div>

      <template v-if="client.self_registration === true">
        <div class="line-separator inverted " />
        <div class="input-group">
          <div class="input-label">
            {{ $t('notification events') }}
          </div>
          <div class="input-value">
            <template v-for="(notificationType, index) in notificationTypes">
              <label :key="'edit_notification_types_' + index">
                <input
                  type="checkbox"
                  :value="notificationType.name"
                  v-model="user[notificationType.name]"
                > {{ $t("notification_type." + notificationType.name) }}<br>
              </label>
            </template>
          </div>
        </div>
      </template>

      <div class="line-separator inverted " />
      <div class="subtitle">
        {{ $t('dialog user fields') }}
      </div>


      <div
        class="input-group"
        v-for="(item) in userfields"
        :key="'edit_userfield_' + item.name"
      >
        <div class="input-label">
          {{ item.title[$i18n.locale] }}
        </div>
        <div class="input-value">
          <input
            class="user-input inverted"
            type="text"
            v-model="user[item.name]"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import PasswordStrength from "@/components/user/PasswordStrength";

export default {
  components: {PasswordStrength},
  data: function () {
    return {
      activeState: null,
      setPassword: false,
      notificationTypes: [
        {
          name: 'receives_registrations',
          default: false
        }
      ],
      modelState: false,
      user: {
        username: '',
        password: '',
        email: '',
        force_pass_change: true,
        roles: [],
        receives_registrations: false
      }
    }
  },
  watch: {
    user: {
      handler: function () {
        this.$store.dispatch('dialogProperties', {user: this.user});
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client',
    }),
    roles: function () {
      if (typeof this.client !== 'undefined') {
        return this.client.roles;
      }
      return [];
    },
    userfields: function () {
      if (typeof this.client !== 'undefined') {
        return this.client.user_fields;
      }
      return [];
    },
    creating: function () {
      return (typeof this.dialog.properties.user.id == 'undefined' || this.dialog.properties.user.id === null);
    }
  },
  mounted: function () {
    if (this.dialog.properties.user.id != null) {
      this.loadUser();
    }
  },
  methods: {
    loadUser: function () {
      this.$store.dispatch('users/show', {id: this.dialog.properties.user.id})
          .then((response) => {
            this.user = response;
            this.activeState = this.user.active;
            this.modelState = this.user.active;
          });
    }
  }
}
</script>