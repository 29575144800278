<template>
  <div class="dialog-content">
    {{ $t('assignment of n items', {count: dialog.properties.items.length}) }}<br><br>
    {{ $t('create a new collection') }}
    <input
      v-model="properties.new_collection_title"
      :placeholder="$t('new title')"
      class="user-input inverted"
      type="text"
    >
    <template v-if="properties.new_collection_title === ''">
      {{ $t('or select a existing collection') }}
      <select
        id=""
        v-model="properties.collection"
        class="user-input inverted"
        name=""
      >
        <option
          v-for="(item, index) in getAvailableCollection"
          :key="'collection_selector_' + index"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      properties: {
        new_collection_title: '',
        collection: null,
        items: []
      }
    }
  },
  mounted: function () {
    this.properties = this.dialog.properties;
  },
  watch: {
    properties: {
      handler: function () {
        this.$store.commit('dialogProperties', this.properties);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      assets: 'assets',
      collections: 'collections',
    }),
    getAvailableCollection: function () {
      return this.collections.items;
    }
  }
}
</script>