import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    timeslices: {},
    from_date: 0,
    to_date: 0,
    interval: '1h',
    paging: {
      listing: {
        page: 1,
        page_size: 10,
        total_pages: 1
      }
    },
    listing: {
      downloads: []
    },
    total: {
      total: 0,
      download: 0,
      page: 0
    }
  }),
  mutations: {
    updateInterval(state, payload) {
      state.interval = payload.interval
    },
    updateListing(state, payload) {

        state.listing[payload.type].splice(0);


      payload.data.items.forEach((item) => {
        state.listing[payload.type].push(item);
      });

      state.from_date = payload.data.from_date;
      state.to_date = payload.data.to_date;
    },
    updateAccessLog(state, payload) {
      state.timeslices[payload.type] = {};
      state.timeslices = payload.data.items;
      state.from_date = payload.data.from;
      state.to_date = payload.data.to;
      state.total = payload.data.total;
    }
  },
  actions: {
    loadCounters({commit, state}) {
      return new Promise((resolve, reject) => {
        var dateRange = '';
        if (state.from_date !== 0 && state.to_date !== 0) {
          dateRange = '&from_date=' + state.from_date + '&to_date=' + state.to_date;
        }
        axios.get('api/access-logs?statistic=counts&type=download&groups[]=asset._id&page=' + state.paging.listing.page + '&page_size=' + state.paging.listing.page_size + dateRange)
          .then((response) => {
            commit('updateListing', {add: state.paging.listing.page > 1, type: 'downloads', data: response.data.items});
            state.paging.listing.total_pages = response.data.total_pages;
            resolve(response);
          }).catch((error) => {
          reject(error);
        })
      })
    },
    loadAccessLog({commit, state}) {
      return new Promise((resolve, reject) => {
        var dateRange = '';
        if (state.from_date !== 0 && state.to_date !== 0) {
          dateRange = '&from_date=' + state.from_date + '&to_date=' + state.to_date;
        }
        axios.get('api/access-logs?statistic=interval&interval=' + state.interval + dateRange)
          .then((response) => {
            commit('updateAccessLog', { add: state.paging.listing.page > 1, type: 'downloads', data: response.data.items});
            resolve(response);
          }).catch((error) => {
          reject(error);
        })
      });
    }
  },
  getters: {}
}