<template>
  <div class="dialog-content">
    <div
      class="form-structure"
      :class="{ readonly : ['admin','media','guest'].includes(role.id) }"
    >
      <div class="subtitle">
        {{ $t('properties') }}
      </div>
      <div class="input-group">
        <div class="input-label">
          {{ $t('id') }}
        </div>
        <div class="input-value">
          <input
            :disabled="!creating"
            class="user-input inverted"
            type="text"
            v-model="role.id"
          >
        </div>
      </div>
      <div>
        <div class="modules">
          <h2>{{ $t('folders') }}</h2>
          <FolderSelector
            v-if="loaded"
            :key="'modules'"
            v-model="role.folder_accesses"
          />
        </div>

        <div class="modules">
          <h2>{{ $t('modules') }}</h2>
          <ModulesSelector
            v-if="loaded"
            :key="'modules'"
            v-model="role.modules"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import ModulesSelector from "@/components/helpers/ModulesSelector";
import FolderSelector from "@/components/helpers/FolderSelector";
export default {
  components: {ModulesSelector, FolderSelector },
  data: function () {
    return {
      creating: false,
      loaded: false,
      role: {
        id: null,
        modules: [],
        folder_accesses: null
      }
    }
  },
  watch: {
    role: {
      handler: function () {
        this.$store.dispatch('dialogProperties', {role: this.role, creating: this.creating});
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client'
    })
  },
  mounted: function () {
    this.role = this.$store.state.dialog.properties.role;
    if (this.$store.state.dialog.properties.role.id !== null) {
      this.load();
    } else {
      this.creating = true;
    }
  },
  methods: {
    load() {
      this.$store.dispatch('roles/load', { id: this.role.id })
          .then((response) => {
            this.role = response;
            this.creating = false;
            this.loaded = true;
          })
    },

  }
}
</script>