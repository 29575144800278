<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('save') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client'
    }),
    userfields: function () {
      if (typeof this.client !== 'undefined') {
        return this.client.user_fields;
      }
      return [];
    }
  },
  methods: {
    defaultAction: function () {
      var dispatcher = 'users/update';
      if(typeof this.dialog.properties.user.id === 'undefined'){
        dispatcher = 'users/store'
      }
      var dialoguser = this.dialog.properties.user;
      var usermodel = {
        id: dialoguser.id,
        username: dialoguser.username,
        active: dialoguser.active,
        roles: dialoguser.roles,
        receives_registrations: dialoguser.receives_registrations
      };
      if(dialoguser.password !== ''){
        usermodel.password = dialoguser.password;
      }

      if(dialoguser.id == null){
        usermodel.email = dialoguser.email;
        usermodel.force_pass_change = true; // TODO: Remove in next update
      }

      this.userfields.forEach((userfield) => {
        usermodel[userfield.name] = dialoguser[userfield.name];
      });

      this.$store.dispatch(dispatcher, { user: usermodel }).then(() => {
        this.$store.commit('addNotification',
            {
              title: 'user updated',
              class: 'highlight',
              data: {},
              datetime: new Date()
            }
        );
        this.$store.dispatch('users/index').then(()=> {
          this.closeDialog();
        });

      }).catch((error) => {
        this.$store.commit('addNotification',
            {
              title: 'saving user failed',
              class: 'error',
              description: error.response.data.error,
              datetime: new Date()
            }
        );
      });
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>