export default {
    namespaced: true,
    state: () => ({
        containers: [],
        mousedown: false,
        target: null,
        states: {
            transfersTabs: 'upload'
        },
        userMenu: {
            active: false
        }
    }),
    mutations: {
        setUserMenuState(state, payload) {
            state.userMenu.active = payload;
        },
        setTransferTab(state, payload) {
          state.states.transfersTabs = payload
        },
        addContainer(state, payload) {
            var container = state.containers.find((item) => {
                if (item.name === payload.properties.name) {
                    return item;
                }
            });

            if (typeof container === 'undefined') {
                state.containers.push(payload.properties);
            }
        },
        mouseDown(state, payload) {
            state.mousedown = true;
            state.target = payload.target;
        },
        mouseMove(state, payload) {
            var index = state.containers.findIndex((item) => {
                if (item.name === state.target) {
                    return true;
                }
            })
            if (index === -1) {
                return;
            }

            var size = state.containers[index].size;


            if (state.containers[index].from_edge === 'left') {
                size += payload.movementX;
            } else if (state.containers[index].from_edge === 'right') {
                size -= payload.movementX;
            } else if (state.containers[index].from_edge === 'top') {
                size += payload.movementY;
            } else if (state.containers[index].from_edge === 'bottom') {
                size -= payload.movementY;
            }

            size = Math.max(state.containers[index].min_size, Math.min(state.containers[index].max_size, size));

            state.containers[index].size = size;
        },
        mouseUp(state) {
            state.mousedown = false;
        },
        collapse(state, payload) {
            var index = state.containers.findIndex((item) => {
                if (item.name === payload.target) {
                    return true;
                }
            })
            if (index === -1) {
                return;
            }
            if(typeof payload.collapsed === 'undefined') {
                state.containers[index].collapsed = !state.containers[index].collapsed;
            } else {
                state.containers[index].collapsed = payload.collapsed;
            }
        },
        container(state, payload) {
            var container = state.containers.find((item) => {
                if (item.name === payload) {
                    return item;
                }
            });

            if (typeof container === 'undefined') {
                return {
                    collapsed: false,
                }
            }

            return container;
        },
    },
    actions: {
        persist({state, rootState}) {
            window.localStorage.setItem('ui.containers', JSON.stringify(state.containers));
            window.localStorage.setItem('asset.collapsible.interactions', JSON.stringify(rootState.assets.interactions));

        },
        retrieve({state, rootState}) {
            if(typeof window.localStorage.getItem('ui.containers') !== 'undefined' && window.localStorage.getItem('ui.containers') !== null) {
                state.containers = JSON.parse(window.localStorage.getItem('ui.containers'));
            }
            if(typeof window.localStorage.getItem('asset.collapsible.interactions') !== 'undefined' && window.localStorage.getItem('asset.collapsible.interactions') !== null) {
                rootState.assets.interactions = JSON.parse(window.localStorage.getItem('asset.collapsible.interactions'));
            }

        },
        clear({state}) {
            window.localStorage.removeItem('ui.containers');
            window.localStorage.removeItem('asset.collapsible.interactions');
            state.containers.splice(0);
        },
        mouseMove({commit, state}, payload) {
            if (state.mousedown !== true) {
                return;
            }

            commit('mouseMove', payload);
        },
        mouseUp({commit, state}) {
            if (state.mousedown !== true) {
                return;
            }
            commit('mouseUp');
        }
    },
    getters: {}
}