<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('ok') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  mounted: function () {
    this.$store.dispatch('collections/index');
  },
  methods: {
    defaultAction: function () {
      var counter = 0;
      var max = this.dialog.properties.items.length;
      if (this.dialog.properties.new_collection_title !== '') {
        axios.post('api/asset-collections', {title: this.dialog.properties.new_collection_title})
            .then((response) => {
              this.dialog.properties.items.forEach((item) => {
                axios.put('api/asset-collections/' + response.data.id + '/assets/' + item)
                    .then(() => {
                      if (max === counter) {
                        this.$store.dispatch('dialogProperties', {new_collection_title: '', collection: null, items: []});
                      }
                    }).catch(() => {
                  // TODO: show notification that assignment of assets to collection failed
                  counter++;
                });
              });
            }).then(() => {
          // TODO: show notification that collection create failed
        });
      }

      this.dialog.properties.items.forEach((item) => {
        axios.put('api/asset-collections/' + this.dialog.properties.collection + '/assets/' + item)
            .then(() => {
              this.$store.dispatch('dialogProperties', {new_collection_title: '', collection: null, items: []});
            })
      });
      this.closeDialog();
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>