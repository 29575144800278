<template>
  <div
    class="help-shield"
    v-if="help.open"
    @click.self="$store.commit('help/helpState',false)"
  >
    <div class="help-container">
      <div class="help-header">
        {{ $t('help') }}
        <div class="help-welcome-text">
          Guten Tag, {{ $store.getters['displayName'] }}. Wie können wir dich unterstützen?
        </div>
      </div>

      <div class="help-content-container">
        <SizeableContainer
          :collapsible="true"
          :default-size="250"
          :detachable="false"
          :from-edge="'left'"
          :max-size="400"
          :min-size="200"
          name="help_navigation"
          :inverted="true"
        >
          <div class="help-navigation">
            <ul>
              <template v-for="(topic, topicKey) in help.topics">
                <li :key="'help_topics_' + topicKey">
                  <div
                    class="help-topic"
                    @click="setTopic(topicKey,'')"
                  >
                    {{ $t(topicKey) }}
                  </div>
                  <template v-for="(item) in topic">
                    <ul
                      class="help-contents"
                      v-if="help.topic === topicKey"
                      :key="'help_topics_' + item.title"
                    >
                      <li
                        v-if="(item.module !== null && $store.state.auth.user.modules.includes(item.module)) || item.module === null"
                        :class="{ active : help.topic === topicKey && item.title === help.item }"
                        @click="setTopic(topicKey,item.title)"
                      >
                        {{ $t('helpcontent.' + item.title) }}
                      </li>
                    </ul>
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </SizeableContainer>
        <div class="help-content">
          <HelpTopic />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import HelpTopic from "@/components/layout/help/HelpTopic";
import SizeableContainer from "@/components/helpers/SizeableContainer";
export default {
  components: {HelpTopic, SizeableContainer},
  data: function () {
    return {

    }
  },
  methods: {
    setTopic(topic, item) {
      this.$store.commit('help/setTopic', {topic: topic, item: item});
    },
    hasRole(itmRoles) {
      var allowed = false;
      var itemRoles = JSON.parse(JSON.stringify(itmRoles));
      if (itemRoles.length === 0) {
        allowed = true;
      }

      // Admin can see anything even if not explicitly defined
      //  itemRoles.push('admin');

      if (typeof this.auth.user === 'undefined') {
        allowed = false;
      }
      itemRoles.forEach((itemRole) => {
        if (this.auth.user.roles.includes(itemRole)) {
          allowed = true;
        }
      });

      return allowed;
    }
  },
  computed: {
    ...mapState({
      auth: 'auth',
      client: 'client',
      help: 'help',
    })
  }
}
</script>