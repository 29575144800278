<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('ok') }}
    </button>
  </div>
</template>
<script>

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      assetSelector: 'assetSelector'
    }),
  },
  methods: {
    defaultAction: function () {
        this.closeDialog();
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>