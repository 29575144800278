<template>
  <div>
    <h2>{{ $t('helpcontent.select new thumbnail') }}</h2>
    {{ $t('helpcontent.asset thumbnail replacement basics') }}
    <h2>{{ $t('helpcontent.revert to original thumbnail') }}</h2>
    {{ $t('helpcontent.revert original thumbnail basics') }}
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      assets: 'assets',
      auth: 'auth',
      client: 'client'
    })
  }
}
</script>