<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button danger"
      @click="defaultAction"
    >
      {{ $t('delete') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  methods: {
    defaultAction: function () {
      this.$store.commit('assets/page', 1);
      this.$store.dispatch('assets/destroy', {items: this.dialog.properties.items});
      this.closeDialog();
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>