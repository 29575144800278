import axios from "axios";

export default {
  namespaced: true,
  state: () => ({}),
  actions: {
    load(_, payload) {
      return new Promise((resolve, reject) => {

        var querystring = '?';

        var keys = Object.keys(payload);

        keys.forEach((item) => {
          querystring += '&' + item + '=';
          if (typeof payload[item] === 'object') {
            querystring += JSON.stringify([payload[item]]);
          } else {
            querystring += payload[item].toString()
          }
        });

        axios.get('api/access-logs' + querystring)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });

      });
    }
  },
  getters: {}
}