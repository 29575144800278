import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    basket: {
      id: null
    }
  }),
  mutations: {
    setBasket(state, payload) {
      state.basket = payload;
    }
  },
  actions: {
    refresh({commit, rootState}) {
      return new Promise((resolve, reject) => {
        var url = 'api/users/' + rootState.auth.user.id + '/basket';
        axios.get(url)
          .then((response) => {
            commit('assets/add', {assets: response.data.assets, append: false}, {root: true});
            commit('setBasket', response.data);

            if (response.data.id != null) {
              rootState.collections.selected_collection = response.data.id;
            }
            resolve(response.data);
          })
          .catch((error) => {
            commit('setBasket', {id: null});
            reject(error);
          })
      });
    },
    addAsset({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        dispatch('refresh')
          .then(() => {
            if (state.basket.id === null) {
              reject();
              return;
            }

            var promises = [];
            payload.items.forEach((item) => {
              promises.push(axios.put('api/asset-collections/' + state.basket.id + '/assets/' + item));
            });
            Promise.allSettled(promises).then(() => {
              dispatch('refresh')
            });
          })
      })
    },
    removeAsset({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        dispatch('refresh')
          .then(() => {
            if (state.basket.id === null) {
              reject();
              return;
            }
            var promises = [];

            payload.items.forEach((item) => {
              promises.push(axios.delete('api/asset-collections/' + state.basket.id + '/assets/' + item));
            });

            Promise.allSettled(promises).then(() => {
              dispatch('refresh')
              commit('assets/clearSelection', null, { root: true });
              resolve();
            });
          })
      })
    },
  },
  getters: {}
}