<template>
  <div
    :style="styling"
    class="sizeable-container"
    :class="fromEdge + ' ' + ((container.collapsed ? 'collapsed' : '') + ' ' + ((inverted === true ? 'inverted':'')))"
  >
    <slot />
    <div
      :class="'edge ' + fromEdge + ((ui.target === name && ui.mousedown === true)? ' active' :'')"
      @mousedown="handleMouseDown"
    />
    <div
      :class="'sizeable-titlebar ' + fromEdge"
      v-if="collapsible"
      @click="collapse"
    >
      <div class="collapsecontent">
        <slot
          v-if="this.$slots.collapsecontent"
          name="collapsecontent"
        />
      </div>
      <div
        v-if="fromEdge === 'left'"
        class="collapseicon icon-chevron_left small"
      />
      <div
        v-if="fromEdge === 'right'"
        class="collapseicon icon-chevron_right small"
      />
      <div
        v-if="fromEdge === 'top'"
        class="collapseicon icon-chevron_up small"
      />
      <div
        v-if="fromEdge === 'bottom'"
        class="collapseicon icon-chevron_down small"
      />
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    minSize: {
      type: Number,
      required: false,
      default: 0
    },
    defaultSize: {
      type: Number,
      required: false,
      default: 350
    },
    maxSize: {
      type: Number,
      required: false,
      default: 2000
    },
    fromEdge: {
      type: String,
      required: true,
      default: 'left'
    },
    detachable: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    ui: {
      handler: function () {
        this.computeSize();
      },
      deep: true
    }
  },
  data: function () {
    return {
      size: this.defaultSize
    }
  },
  computed: {
    ...mapState({
      ui: 'ui',
    }),
    container() {
      var container = this.ui.containers.find((item) => {
        if (item.name === this.name) {
          return item;
        }
      });

      if (typeof container === 'undefined') {
        return {
          collapsed: false,
        }
      }

      return container;
    },
    styling() {
      var style = '';
      if (this.fromEdge === 'left' || this.fromEdge === 'right') {
        style += 'max-width: ' + this.size + 'px;';
      } else {
        style += 'max-height: ' + this.size + 'px;';
      }
      return style;
    },
  },
  mounted: function () {
    this.init();
    this.size = this.container.size ?? this.defaultSize;
  },
  methods: {
    collapse() {
      if (this.collapsible) {
        this.$store.commit('ui/collapse', {target: this.name});
        this.$store.dispatch('ui/persist');
      }
    },
    computeSize() {
      var container = this.ui.containers.find((item) => {
        if (item.name === this.name) {
          return item;
        }
      });

      if (typeof container === 'undefined') {
        this.size = this.defaultSize;
      } else {
        this.size = container.size;
      }
    },
    init() {
      this.$store.commit('ui/addContainer', {
        properties: {
          name: this.name,
          min_size: this.minSize,
          default_size: this.defaultSize,
          max_size: this.maxSize,
          from_edge: this.fromEdge,
          detachable: this.detachable,
          size: this.container.size || this.defaultSize,
          collapsible: this.collapsible,
          collapsed: false
        }
      });
    },
    handleMouseDown() {
      this.$store.commit('ui/mouseDown', {target: this.name});
    }
  }
}
</script>