<template>
  <div
    v-if="password.length > 0"
    class="password-strength"
  >
    <div v-if="requirementMet">
      {{ $t('password minimum requirements met') }}
    </div>
    {{ $t('password strength check') }}: <span :class="'progress_warn_level_' + score">{{ $t('password level ' + score) }}</span>
    <div class="progress-bar-total">
      <div
        :class="'progress_warn_level_' + score"
        :style="{ width: score_percentage + '%' }"
        class="progress-bar-value"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  props: {
    password: {
      type: String,
      default: ''
    },
    userdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      score: 0,
      last_password: this.password,
      interval: null
    }
  },
  computed: {
    ...mapState({
      auth: 'auth',
      client: 'client'
    }),
    score_percentage: function () {
      return 100 / 5 * (this.score + 1);
    },
    requirementMet: function () {
      return this.score >= this.client.min_password_strength;
    }
  },
  mounted: function () {
    this.inverval = setInterval(() => {
      if (this.password !== this.last_password) {
        this.check_strength();
      }
    }, 2500)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    password: function () {
      // this.check_password = this.password;
      // this.check_strength();
    }
  },
  methods: {
    check_strength: function () {
      axios.post('password-strength', {password: this.password, username: this.userdata.username, email: this.userdata.email})
          .then((response) => {
            this.score = response.data.score;
            this.last_password = response.data.password;
            this.$emit('input', this.score);
          })
    }
  }
}
</script>