import Vue from 'vue'
import Vuex from 'vuex'

import assets from './modules/assets';
import collections from './modules/collections';
import folders from './modules/folders';
import auth from './modules/auth';
import client from './modules/client';
import basket from './modules/basket';
import dialog from './modules/dialog';
import dragdrop from './modules/dragdrop';
import tags from './modules/tags';
import transfers from './modules/transfers';
import notifications from './modules/notifications';
import lightbox from './modules/lightbox';
import links from './modules/links';
import users from './modules/users';
import renderqueue from './modules/renderqueue';
import ui from './modules/ui';
import assetSelector from './modules/assetSelector';
import comments from './modules/comments';
import roles from './modules/roles';
import help from './modules/help';
import statistics from './modules/statistics';
import documents from './modules/documents';
import publicpage from './modules/publicpage';
import accesslogs from './modules/accesslogs';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    assets,
    folders,
    collections,
    auth,
    client,
    basket,
    roles,
    dialog,
    dragdrop,
    tags,
    transfers,
    notifications,
    lightbox,
    links,
    users,
    renderqueue,
    ui,
    assetSelector,
    comments,
    help,
    documents,
    statistics,
    publicpage,
    accesslogs
  }
});
