<template>
  <div class="dialog-content">
    <div
      class="button dialog-button"
      @click="assetRestoreOriginalThumbnail"
    >
      {{ $t('restore original thumbnail') }}
    </div>
    {{ $t('select thumbnail to use') }}

    {{ $t('image purpose') }}
    <select v-model="properties.source_purpose">
      <option value="web">
        Web
      </option>
      <option value="original">
        Original
      </option>
      <option value="original">
        Animation
      </option>
    </select>
    <AssetSelector
      v-model="properties.replacement"
      :multi="false"
      :hide="[]"
    />
  </div>
</template>
<script>
import {mapState} from "vuex";
import AssetSelector from "@/components/layout/assets/AssetSelector";

export default {
  components: { AssetSelector},
  data: function () {
    return {
      properties: {}
    }
  },
  mounted: function () {
    this.properties = this.dialog.properties;
    this.loaded = true;
  },
  methods: {
    assetRestoreOriginalThumbnail() {
      this.$store.dispatch('assets/regeneratePurpose', {items: this.dialog.properties.items, purpose : "web" });
      this.closeDialog()
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  },
  watch: {
    properties: {
      handler: function () {
        if (this.loaded === true) {
          this.$store.commit('dialogProperties', this.properties);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
    }),
  }
}
</script>