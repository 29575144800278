<template>
  <div>
    <div
      class="link highlight no-padding clickable"
      @click="openUserManagement"
    >
      {{ $t('helpcontent.open users management') }}
    </div>
    <template v-if="client.self_registration_active">
      {{ $t('helpcontent.activate user basics auto active') }}
    </template>
    <template v-if="!client.self_registration_active">
      {{ $t('helpcontent.activate user basics auto inactive') }}
    </template>

    <template v-if="$store.state.auth.user.modules.includes('users')">
      {{ $t('helpcontent.user management activate') }}
      <br>
    </template>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
      passwordtest: '',
      strength: 0
    }
  },
  methods: {
    openUserManagement() {
      this.$store.commit('help/helpState', false);
      this.$router.push('/users')
    }
  },
  props: {
    auth: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    help: {
      type: Object,
      required: true
    }
  }

}
</script>