<template>
  <div>
    <div
      class="link highlight no-padding clickable"
      @click="openUserManagement"
    >
      {{ $t('helpcontent.open users management') }}
    </div>

    {{ $t('helpcontent.users management basics') }}

    <h2>Weiterführende Aktionen</h2>
    <div
      @click="create"
      class="clickable link no-padding highlight"
    >
      Benutzer erstellen
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    openUserManagement() {
      this.$store.commit('help/helpState', false);
      this.$router.push('/users')
    },
    create: function () {

      this.$store.commit('help/helpState', false);
      this.$router.push('/users')

      this.$store.dispatch('dialogProperties', {user: {}});
      this.$store.dispatch('dialogMessage', '');
      this.$store.dispatch('dialogTitle', this.$t('user'));
      this.$store.dispatch('dialogActionComponent', 'ActionUserEdit');
      this.$store.dispatch('dialogBodyComponent', 'BodyUserEdit');
      this.$store.dispatch('showDialog', {modal: true, width: 1000});
    }
  }
}
</script>