import axios from "axios";
import router from './../../router'

export default {
  namespaced: true,
  state: () => ({
    assetType: 'folders',
    batchSize: 50,
    editorDirty: false,
    loading: false,
    proxyLoaded: false,
    selected_path: '/',
    selected_asset_collection: null,
    thumbnailBackground: 'ch-dark',
    viewer: false,
    zoom: 160,
    exports: [],
    items: [],
    selection: [],
    shared_links: [],
    latestRequest: 0,
    uploads: [],
    assetCollections: {
      folders: [],
      collections: []
    },
    context_menu: {
      type: 'assetslisting',
      active: false,
      context_position: {x: 0, y: 0},
      item: null
    },
    exportFormats: ['jpg', 'png', 'tiff', 'webp'],
    filters: {
      search: '',
      sort: {
        on: 'original_filename',
        asc: true,
      },
      show_descendant_assets: true,
      hide_archived: false
    },
    paging: {
      page: 1,
      pageSize: 200,
      total_items: 0,
      page_count: 1
    },
    interactions: {
      comments: false,
      properties: true,
    },
    metadata_filters: {
      ratings: [],
      mimetypes: [],
      folder_id: [],
      tags: []
    },
    metadata_text_filters: {},
    sidepanel: {
      active_tab: 'browse',
      visible: false
    }
  }),
  mutations: {
    clearFilterState(state, payload) {
      delete state.metadata_filters[payload.type];
    },
    setFilterState(state, payload) {
      if (payload.value === '') {
        delete state.metadata_filters[payload.type];
      } else {
        state.metadata_filters[payload.type] = payload.value;
      }
      state.paging.page = 1;
    },
    setTextFilterState(state, payload) {
      if (payload.value === '') {
        delete state.metadata_text_filters[payload.type];
      } else {
        state.metadata_text_filters[payload.type] = payload.value;
      }
      state.paging.page = 1;
    },
    sidepanelVisibility(state, payload) {
      state.sidepanel.visible = payload;
    },
    setLoadingState(state, payload) {
      state.loading = payload;
    },
    add(state, payload) {
      if (payload.append === true) {
        if (typeof payload.assets !== 'undefined') {
          payload.assets.forEach((item) => {
            state.items.push(item);
          });
        }
      } else {
        state.items.splice(0,state.items.length, ...payload.assets);
      }
      state.editorDirty = false;
    },
    contextmenu(state, payload) {
      state.context_menu = payload;
    },
    viewer(state, payload) {
      state.viewer = payload;
    },
    clearSelection(state) {
      state.selection.splice(0);
    },
    setDirty(state, payload) {
      state.editorDirty = payload;
    },
    proxyLoaded(state, payload) {
      state.proxyLoaded = payload;
    },
    selection(state, payload) {
      state.editorDirty = false;
      state.proxyLoaded = false;
      if (typeof payload.item === 'object') {
        state.selection = payload.item;
        return;
      }
      if (!payload.modifier) {
        state.selection = [payload.item];
        return;
      }

      if (payload.shift && payload.shift === true) {
        var from = state.items.findIndex((item) => {
          if (item.id === state.selection[state.selection.length - 1]) {
            return item;
          }
        });
        var to = state.items.findIndex((item) => {
          if (item.id === payload.item) {
            return item;
          }
        });

        if (from > to) {
          [from, to] = [to, from]
        }

        state.items.forEach((item, index) => {
          if (from <= index && to >= index && !state.selection.includes(item.id)) {
            state.selection.push(item.id);
          }
        });
        return;
      }
      if (state.selection.includes(payload.item)) {
        state.selection.splice(state.selection.indexOf(payload.item), 1);
      } else {
        state.selection.push(payload.item);
      }

    },
    assetsUploads(state, payload) {
      state.uploads = payload;
    },
    rating(state, payload) {
      var asset_index = state.items.map(e => e.id).indexOf(payload.item);
      if (typeof state.items[asset_index] !== 'undefined') {
        state.items[asset_index].rating = payload.rating;
      }
    },
    type(state, payload) {
      if (state.assetType !== payload.type) {
        state.paging.page = 1;
        state.assetType = payload.type;
      }
    },
    sidepanelIndex(state, payload) {
      state.sidepanel.active_tab = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    page(state, payload) {
      state.paging.page = payload;
    },
    sharedLinks(state, payload) {
      state.shared_links = payload.links;
    },
    clearCache(state) {

      // Remove loaded data
      state.assetCollections.collections.length = 0;
      state.assetCollections.folders.length = 0;
      state.items.length = 0;
      state.uploads.length = 0;
      state.shared_links.length = 0;
      state.selection.length = 0;

      // Remove export preferences
      window.localStorage.removeItem('exportSettings');
    },
    exportAssets(state, payload) {
      var exportItem = {
        properties: payload,
        expanded: false,
        job_state: 0,
        date: new Date().toISOString(),
      }
      state.exports.push(exportItem)
    },

    setZoom(state, payload) {
      state.zoom = Math.min(1000, Math.max(1, payload));
    },
    setThumbnailBackground(state, payload) {
      state.thumbnailBackground = payload;
    }
  },
  actions: {
    load({commit, state, rootState, rootGetters}) {
      return new Promise((resolve, reject) => {
        const page = state.paging.page;
        commit('setLoadingState', true);
        var assetType = state.assetType.slice();
        var selected_path = state.selected_path;

        var url;

        var filters = [];
        var sorters = [];

        if (assetType === 'folders') {
          url = 'api/assets?';
        } else if (assetType === 'basket') {
          url = 'api/asset-collections/' + rootState.collections.selected_collection + '/assets?';
        } else if (assetType === 'collections') {
          url = 'api/asset-collections/' + rootState.collections.selected_collection + '/assets?';
        } else if (assetType === 'keywords') {
          url = 'api/assets?';
          if (rootState.tags.selected != null) {
            filters.push({
                property: 'tags',
                operator: 'in',
                value: [rootState.tags.selected]
              }
            );
          }
        }

        if (selected_path === 'uploads') {
          url = 'api/assets?';
          filters.push({
            property: 'path',
            operator: 'notexists'
          });
        }

        if (['folders'].includes(state.assetType) && selected_path !== 'uploads') {
          if (rootState.folders.selected_folder !== null) {

            if (state.filters.show_descendant_assets) {
              var folderIds = [];
              if (typeof rootGetters["folders/currentFolder"] !== 'undefined') {
                folderIds = rootGetters["folders/currentFolder"].descendants;
              }

              folderIds.push(rootState.folders.selected_folder);

              filters.push({
                property: 'folder_id',
                operator: 'in',
                value: folderIds
              });

            } else {
              filters.push({
                property: 'folder_id',
                operator: '=',
                value: rootState.folders.selected_folder
              });
            }
          } else if (!state.filters.show_descendant_assets) {
            {
              filters.push({
                property: 'folder_id',
                operator: '=',
                value: null
              });
              filters.push({
                property: 'new',
                operator: '=',
                value: false
              });
            }
          }

          if (state.filters.hide_archived) {
            filters.push({
              property: 'archived',
              operator: '=',
              value: false
            })
          }
        }

        if (typeof router.currentRoute.query.asset !== 'undefined') {
          rootState.tags.selected = null;
          filters.splice();
          filters.push({
            property: 'id',
            operator: '=',
            value: router.currentRoute.query.asset
          });
        }

        if (state.filters.search !== '') {
          filters.push({
            property: 'name',
            operator: 'like',
            value: state.filters.search
          });
        }

        Object.keys(state.metadata_filters).forEach((key) => {
          if (state.metadata_filters[key].length > 0) {
            filters.push({
              property: key,
              operator: 'in',
              value: state.metadata_filters[key]
            })
          }
        });

        Object.keys(state.metadata_text_filters).forEach((key) => {
          if (state.metadata_text_filters[key].length > 0) {
            filters.push({
              property: key,
              operator: 'like',
              value: state.metadata_text_filters[key]
            })
          }
        });

        // Sorters
        sorters.push({
          property: state.filters.sort.on,
          direction: state.filters.sort.asc ? 'asc' : 'desc'
        });

        if (sorters.length > 0) {
          url += '&sort=' + JSON.stringify(sorters);
        }
        if (state.assetType !== 'collections') {
          if (filters.length > 0) {
            url += '&filter=' + JSON.stringify(filters);
          }
        }

        // Paging
        url += '&page=' + state.paging.page + '&page_size=' + state.paging.pageSize;
        if (state.paging.page_count < state.paging.page) {
          //   return;
        }

        state.latestRequest++;
        url += '&request=' + state.latestRequest;

        axios.get(url)
          .then((response) => {
            const urlParams = new URLSearchParams(response.config.url);

            if (state.paging.page === 1 && state.latestRequest != urlParams.get('request')) {
              resolve();
              return;
            }

            // Ignore slow loading resource that's not required
            if (assetType === state.assetType && selected_path === state.selected_path) {

              // Append or replace assets list based on current page number
              var data = {assets: response.data.assets, append: false};
              if (page > 1) {
                data.append = true;
              }
              state.paging.total_items = response.data.total_items;
              state.paging.page_count = response.data.page_count;
              commit('add', data);
              commit('setLoadingState', false);
              resolve();
            }
          }).catch(() => {
          commit('setLoadingState', false);
          commit('addNotification',
            {
              title: 'error loading assets',
              class: 'error',
              datetime: new Date()
            },
            {root: true}
          );
          reject();
        });
      });
    },
    regeneratePurpose(_, payload) {
      return new Promise((resolve, reject) => {
        var promises = [];
        payload.items.forEach((item) => {
          promises.push(axios.post('api/assets/' + item + '/images', {
            purpose: payload.purpose
          }));
        });
        Promise.allSettled(promises).then(() => {
          resolve();
        })
          .then(() => {
            reject();
          })
      });
    },
    patch({state, rootState, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        var urlPrefix = 'api/assets/';
        if (state.assetType == 'collections') {
          urlPrefix = 'api/asset-collections/' + rootState.collections.selected_collection + '/assets/';
        }
        axios.patch(urlPrefix + payload.items.join(','), payload.properties)
          .then((response) => {
            dispatch('load');
            resolve(response);
          }).catch((error) => {
          reject(error);
        });
      });
    },
    download({rootState, rootGetters}, payload) {
      return new Promise((resolve, reject) => {

        /* Front Downloads for unauthenticated users */
        if (payload.lightbox === true && !rootGetters['loggedIn']) {
          axios.post('front/lightbox/' + rootState.lightbox.properties.id + '/assets/' + payload.id + '/downloads/' + payload.purpose, payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            })
          return;
        }

        // Regular downloads
        axios.post('api/assets/' + payload.id + '/downloads', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    exportAssets({commit}, payload) {
      axios.post('api/assets/export', payload)
        .then(() => {
        }).catch(() => {
      });
      commit('exportAssets', payload);
    },
    contextmenu({commit}, payload) {
      commit('contextmenu', payload);
    },
    closeContextmenu({commit}) {
      commit('contextmenu', {type: '', item: null, context_position: {x: 0, y: 0}, active: false});
    },
    viewer({commit}, payload) {
      commit('viewer', payload);
    },
    selectAll({commit, state}) {
      var ids = state.items.map((item) => {
        return item.id;
      });
      commit('selection', {item: ids})
    },
    selection({commit, dispatch}, payload) {
      commit('selection', payload);
      dispatch('loadSingleAsset');
    },
    loadSingleAsset({state, rootState}) {
      return new Promise((resolve) => {
      var assetType = state.assetType.slice();
      var selectedAssetId = state.selection[0];

      if (['folders', 'keywords'].includes(assetType)) {
        if (state.selection.length !== 1) {
          return;
        }

        axios.get('api/assets/' + selectedAssetId)
          .then((response) => {
            var itemIndex = state.items.findIndex((item) => {
              if (item.id === selectedAssetId) {
                return true;
              }
            });
            if (itemIndex >= 0) {
              state.items[itemIndex] = response.data;
            }
          }).finally(resolve())
        return;
      }
      if (assetType === 'collections') {
        var collection = rootState.collections.selected_collection;

        axios.get('api/asset-collections/' + rootState.collections.selected_collection + '/assets/' + selectedAssetId)
          .then((response) => {
            if (collection !== rootState.collections.selected_collection) {
              return false;
            }
            var itemIndex = state.items.findIndex((item) => {
              if (item.id === selectedAssetId) {
                return true;
              }
            });
            if (itemIndex >= 0) {
              state.items[itemIndex] = response.data;
            }
          })
          .finally(resolve())
      }
      })
    },
    destroy({dispatch, commit}, payload) {
      payload.items.forEach((item) => {
        axios.delete('api/assets/' + item)
          .then(() => {
          });
      });

      setTimeout(() => {
        commit('clearSelection');
        dispatch('load', {root: true});
        dispatch('updateUploaded', null, {root: true});
        commit('addNotification',
          {
            title: 'assets deleted',
            class: 'highlight',
            data: {
              count: payload.items.length
            },
            datetime: new Date()
          },
          {root: true}
        );
      }, 1000);

    },
    removeFromCollection({dispatch, commit}, payload) {
      return new Promise((resolve) => {
        var promises = [];

        payload.items.forEach((item) => {
          promises.push(axios.delete('api/asset-collections/' + payload.asset_collection_id + '/assets/' + item));
        });

        Promise.allSettled(promises).then(() => {
          commit('addNotification',
            {
              title: 'assets removed from collection',
              class: 'highlight',
              data: {
                count: payload.items.length,
              },
              datetime: new Date()
            },
            {root: true}
          );
          dispatch('load');
          resolve();
        });
      });
    },
    removeFromBasket({dispatch, commit}, payload) {
      axios.delete('api/basket-assets', {data: {assets: payload.items}})
        .then(() => {
          commit('addNotification',
            {
              title: 'assets removed from basket',
              class: 'highlight',
              data: {
                count: payload.items.length,
              },
              datetime: new Date()
            }
          );
          dispatch('load');
        });
    },
    ratingAnonymous(payload) {
      axios.patch('front/assets/' + payload.id, {rating: payload.rating})
        .then(() => {
        });
    },
    rating({dispatch, commit}, payload) {
      if (payload.item.length > 1) {
        for (var i = 0; i < payload.item.length; i++) {
          commit('rating', {item: payload.item[i].id, rating: payload.rating});
        }
      } else {
        commit('rating', {item: payload.item.id, rating: payload.rating});
      }

      axios.patch('api/assets/' + payload.item.id, {rating: payload.rating})
        .then(() => {
          dispatch('loadSingleAsset', [payload.item.id])
        })
    },
    uploads({commit}) {
      axios.get('api/assets/uploads')
        .then((response) => {
          commit('uploads', response.data);
        }).catch(() => {
      });
    },
    type({commit}, payload) {
      // TODO: Check if assetLoad is required after setting the asset type
      commit('clearSelection');
      commit('type', payload);
      //   dispatch('assets/load');
    },
    setFilters({commit, dispatch}, payload) {
      commit('setFilters', payload.filters);
      commit('clearSelection');
      dispatch('load');
    },
    page({commit}, payload) {
      commit('page', payload);
    },
    sortorder({commit, rootState}, payload) {
      return new Promise((resolve, reject) => {
        var url_prefix = 'api/assets/';
        if ((rootState.assets.assetType === 'collections' || rootState.assets.assetType === 'basket') && rootState.collections.selected_collection !== null) {
          url_prefix = 'api/asset-collections/' + rootState.collections.selected_collection + '/assets/';
        }
        if (rootState.assets.assetType === 'keywords') {
          commit('addNotification',
            {
              title: 'error sorting in keywords',
              class: 'error',
              data: {},
              description: null,
              datetime: new Date()
            }, {
              root: true
            }
          );
          return;
        }

        if (rootState.assets.filters.sort.on !== "sort_index") {
          commit('addNotification',
            {
              title: 'error sorting not allowed',
              class: 'error',
              data: {sortname: rootState.assets.filters.sort.on},
              description: null,
              datetime: new Date()
            }, {
              root: true
            }
          );
        }
        axios.patch(url_prefix + payload.source.id, {
          sort_index: payload.sorttarget + ':' + payload.target.id
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit('addNotification',
              {
                title: 'error sorting',
                class: 'error',
                data: {},
                description: error.response.data.error,
                datetime: new Date()
              }, {
                root: true
              }
            );
            reject(error);
          });
      });
    },
    sharedLinks({commit}) {
      axios.get('api/links')
        .then((response) => {
          commit('sharedLinks', response.data);
        });
    },
    move(commit, payload) {
      return new Promise((resolve, reject) => {
        axios.patch('api/assets/' + payload.source.id, {folder_id: payload.target.id}).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      })
    },
    moveMultiple(commit, payload) {
      return new Promise((resolve, reject) => {
        var chunks = [];
        var promises = [];
        for (let i = 0; i < payload.source.length; i += 25) {
          chunks.push(payload.source.slice(i, i + 25));
        }

        chunks.forEach((chunk) => {
          promises.push(axios.patch('api/assets/' + chunk.join(','), {folder_id: payload.target.id}));
        })

        Promise.allSettled(promises)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          })
      })
    },
    clearCache({commit}) {
      commit('clearCache');
    },
    setPreviewAsset(_, payload) {
      return new Promise((resolve, reject) => {
        axios.put('api/assets/' + payload.id + '/images', {
          purpose: 'web',
          source: payload.thumbnail_asset_id
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    show(_, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/assets/' + payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    }
  },
  getters: {
    isFilterActive(state) {
      var isActive = false;
      var names = Object.keys(state.metadata_filters);

      names.forEach((key) => {
        if (state.metadata_filters[key].length > 0) {
          isActive = true;
        }
      });

      if (Object.keys(state.metadata_text_filters).length > 0) {
        isActive = true;
      }

      if (state.metadata_filters.ratings.length > 0) {
        isActive = true;
      }

      if (state.metadata_filters.mimetypes.length > 0) {
        isActive = true;
      }

      return isActive;
    },
    sortedFiltered: (state, getters, rootState) => {
      if (state.assetType !== 'collections' && rootState.client.filter_engine === "server") {

        return state.items;
      } else if (state.assetType === 'collections' || state.assetType === 'basket' || rootState.client.filter_engine === "local") {

        var filtered = state.items.filter(f => {
          return f.name.toString().toLowerCase().includes(state.filters.search.toString().toLowerCase());
        });

        var f = (obj, i) => {
          return obj[i]
        };

        filtered = filtered.sort((a, b) => {
          var item_a = state.filters.sort.on.split('.').reduce(f, a);
          var item_b = state.filters.sort.on.split('.').reduce(f, b);
          if (typeof item_a == 'undefined' || item_a === null) {
            item_a = 0;
          }
          if (typeof item_b == 'undefined' || item_b === null) {
            item_b = 0;
          }

          if (state.filters.sort.asc) {
            return (item_a > item_b) ? 1 : -1;
          } else {
            return (item_a > item_b) ? -1 : 1;
          }
        });

        return filtered;
      }
      return [];
    },
    selectionItem: (state, getters) => {
      if (state.selection.length > 0) {
        return getters['sortedFiltered'].find(item => item.id === state.selection[0])
      }
      return [];
    },
    getZoom: (state) => {
      return state.zoom;
    },
    selectionIndex: (state, getters) => {
      return getters.sortedFiltered.map(e => e.id).indexOf(state.selection[0]);
    },
    nextItem: (state, getters) => {
      var selection;
      if (getters.selectionIndex + 1 >= getters.sortedFiltered.length) {
        selection = getters.sortedFiltered[0].id;
      } else {
        selection = getters.sortedFiltered[getters.selectionIndex + 1].id;
      }
      return selection;
    },
    previousItem: (state, getters) => {
      var selection;
      if (getters.selectionIndex <= 0) {
        selection = getters.sortedFiltered[getters.sortedFiltered.length - 1].id;
      } else {
        selection = getters['sortedFiltered'][getters['selectionIndex'] - 1].id;
      }
      return selection;
    },
    downItem: (state, getters) => {
      // Get the next item down the row based on asset column count
      var selection = getters['selectionIndex'] + Math.floor(100 / (9.85 + (state.zoom / 11.11111)));

      if (selection > getters.sortedFiltered.length - 1) {
        return getters.sortedFiltered[getters['selectionIndex']].id;
      }
      return getters.sortedFiltered[selection].id;
    },
    upItem: (state, getters) => {
      // Get the previous item up the row based on asset column count
      var selection = getters['selectionIndex'] - Math.floor(100 / (9.85 + (state.zoom / 11.11111)));

      if (selection < 0) {
        return getters.sortedFiltered[getters['selectionIndex']].id;
      }
      return getters.sortedFiltered[selection].id;
    }
  }
}