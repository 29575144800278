import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    updateKey: 0
  }),
  mutations: {},
  actions: {
    save({rootState}, payload) {
      if (typeof payload.role.folder_accesses === 'undefined') {
        payload.role.folder_accesses = null;
      }
      return new Promise((resolve, reject) => {
        if (rootState.dialog.properties.creating === true) {
          axios.post('api/clients/' + rootState.client.id + '/roles', payload.role)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          axios.patch('api/clients/' + rootState.client.id + '/roles/' + payload.role.id, payload.role)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }

      });
    },
    load({rootState}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/clients/' + rootState.client.id +
          '/roles/' +
          payload.id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    update() {

    }
  },
  getters: {}
}