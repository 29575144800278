<template>
  <div class="dialog-actions">
    <button
      v-if="['admin','media','guest'].includes(this.dialog.properties.role.id)"
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('close') }}
    </button>
    <template v-else>
      <button
        class="button dialog-button"
        @click="closeDialog"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="button dialog-button highlight"
        @click="defaultAction"
      >
        {{ $t('save') }}
      </button>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";
export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client'
    }),
  },
  methods: {
    defaultAction: function () {
      this.$store.dispatch('roles/save', { role: this.dialog.properties.role })
          .then(() => {
            this.$store.dispatch('hideDialog');
          })

    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>