<template>
  <div class="dialog-content">
    <div class="form-structure">
      <div class="input-value">
        <label>
          <input
            class="user-input checkbox inverted"
            type="checkbox"
            v-model="assets.filters.show_descendant_assets"
          >
          {{ $t('show subfolder assets') }}<br>

          <small>{{ $t('show subfolder assets description') }}</small>
        </label>
      </div>
      <br>
      <div class="input-value">
        <label>
          <input
            class="user-input checkbox inverted"
            type="checkbox"
            v-model="assets.filters.hide_archived"
          >
          {{ $t('hide archived assets') }}<br>

          <small>{{ $t('hide archived assets description') }}</small>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
    }
  },
  watch: {
    showDescendants: {
      handler: function() {
        this.persist('assets.filters.show_descendants', this.assets.filters.show_descendant_assets)
      }
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      assets: 'assets'
    }),
    showDescendants(){
      return this.assets.filters.show_descendant_assets;
    }
  },
  methods: {
    persist(key, value){
      window.localStorage.setItem(key, value.toString());
    }
  }
}
</script>