export default {
  namespaced: true,
  state: () => ({
    open: false,
    topic: '',
    item: '',
    topics: {
      user: [
        {
          title: 'register',
          roles: [],
          auth: true,
          component: 'UserRegister',
          content: null,
          keywords: 'benutzer user registrieren login benutzername email passwort anmelden',
          module: null
        },
        {
          title: 'activate user',
          roles: ['media'],
          auth: true,
          component: 'UserActivate',
          content: null,
          module: null
        },
        {
          title: 'users management',
          roles: ['admin'],
          component: 'UserManagement',
          auth: true,
          module: null
        }
      ],
      "asset management": [
        {
          title: 'upload',
          roles: ['media'],
          component: 'AssetUpload',
          content: null,
          auth: true,
          module: null
        },
        {
          title: 'download',
          roles: ['media'],
          component: 'AssetDownload',
          content: null,
          auth: true,
          module: null
        },
        {
          title: 'export',
          roles: ['media'],
          component: 'AssetExport',
          content: null,
          auth: true,
          module: null
        },
        {
          title: 'share',
          roles: [],
          component: 'AssetCollectionShare',
          content: null,
          auth: true,
          module: 'share'
        },
        {
          title: 'replace preview image',
          roles: [],
          component: 'AssetThumbnailReplacement',
          content: null,
          auth: true,
          module: null
        },
        {
          title: 'asset derivatives',
          roles: [],
          component: 'AssetDerivatives',
          content: null,
          auth: true,
          module: null
        }
      ]
      /*
      Additional help topics here
      - User Management
      - Settings
      - Profile
      - Keywords
      - Shared Links
      - Mandant Settings
      - Languages
      - Best Practices
       */
    }
  }),
  mutations: {
    helpState(state, payload) {
      state.open = payload;
    },
    setTopic(state, payload) {
      state.topic = payload.topic;
      state.item = payload.item;
    }
  },
  actions: {}
}