<template>
  <div class="dialog-content">
    <div class="form-structure">
      <div class="subtitle">
        {{ $t('properties') }}
      </div>


      <div class="input-group">
        <div class="input-label">
          {{ $t('name') }}
        </div>
        <div class="input-value">
          <input
            v-model="tag.name"
            class="user-input inverted"
            type="text"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      tag: {
        name: '',
      }
    }
  },
  watch: {
    tag: {
      handler: function () {
        this.$store.dispatch('dialogProperties', {tag: this.tag});
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      client: 'client',
    }),

    creating: function () {
      return (typeof this.dialog.properties.tag.id == 'undefined' || this.dialog.properties.tag.id === null);
    }
  },
  mounted: function () {
    if (this.dialog.properties.tag.id != null) {
      this.loadTag();
    }
  },
  methods: {
    loadTag: function () {
      this.$store.dispatch('tags/show', {id: this.dialog.properties.tag.id})
          .then((response) => {
            this.tag = response;
            this.activeState = this.tag.active;
          });
    }
  }
}
</script>