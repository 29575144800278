<template>
  <div class="asset-selector">
    <div class="asset-selector-filters">
      <input
        type="text"
        class="user-input inverted"
        v-model="search"
        @keydown.enter.prevent="searchFilter"
        @keyup="searchFilter"
        :placeholder="$t('search')"
      >
    </div>
    <div class="asset-selector-listing">
      <template v-for="(item) in assetSelector.items">
        <div
          v-if="!hide.includes(item.id)"
          :key="'asset_thumb_' + item.id"
          class="asset-item"
          @click="selection = [item.id]"
          :class="{ active: assetSelector.selection.includes(item.id) }"
        >
          <div
            class="select-icon icon-checked  "
            :class="{ 'disabled faded': !assetSelector.selection.includes(item.id), 'select-icon icon-checked ': assetSelector.selection.includes(item.id) }"
          />
          <div class="asset-thumb">
            <img
              :src="item.cdn_url + '?auto=format&fit=fill&fill=solid&w=96&h=96&orient=' + item.cdn_orientation + '&version=1'"
              alt=""
            >
          </div>
          <div class="asset-label">
            {{ item.name }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    multi: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Array,
      default: function(){
        return []
      }
    },
    value: {
      type: [Array, String],
      default: function(){
        return [];
      }
    }
  },
  data: function(){
    return {
      selection: [],
      search: ''
    }
  },
  mounted: function(){
    this.selection = this.value;

    this.search = this.assetSelector.search;
    this.load();
  },
  methods: {
    load() {
      this.$store.dispatch('assetSelector/index');
    },
    searchFilter() {
      this.$store.commit('assetSelector/setSearch',this.search);
      this.load();
    }
  },
  watch: {
    selection: {
      handler: function() {
        if(Array.isArray(this.selection)) {
          this.$store.commit('assetSelector/setSelection', this.selection);
          this.$emit('input', this.selection[0]);
        }
      },
      deep: true
    },
    value: {
      handler: function() {
        if(typeof this.value === 'string'){
          this.selection = [this.value];
        } else if(typeof this.value === 'object'){
          this.selection = this.value;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      assetSelector: 'assetSelector',
      client: 'client',
      assets: 'assets'
    }),
  }
}
</script>