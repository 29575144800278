<template>
  <div class="dialog-content">
    <div
      style="height:100px;"
      v-if="loading"
    >
      <div class="inline">
        <div class="spinner" />
      </div>
    </div>
    <template v-if="!loading">
      <div class="property-highlight-container inverted">
        <div class="property-highlight-data">
          <div class="property-highlight-values">
            <div class="icon-link" />
          </div>
          <div class="property-highlight-values">
            <a
              :href="share_link"
              class="value is-link break-any"
              target="_blank"
            >{{ share_link }}</a>
          </div>
        </div>
      </div>

      <div class="form-structure">
        <div class="subtitle">
          {{ $t('general') }}
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('title') }}
          </div>
          <div class="input-value disabled text-only">
            {{ link.name }}
          </div>
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('shared collection') }}
          </div>
          <div class="input-value text-only">
            <div
              class=""
              style="position: relative;"
              v-if="loadingCollection"
            >
              <div class="spinner" />
            </div>
            <a
              v-else
              :href="'/assets/#collections:' + collection.id "
              target="_blank"
              class="highlight link highlight-text-only no-padding"
            > {{ collection.name }}</a>
          </div>
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('created_on') }}
          </div>
          <div class="input-value disabled text-only">
            {{ $date(link.created_on).format('DD.MM.YYYY, HH:mm') }}
          </div>
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('validity date') }}
          </div>
          <div class="input-value disabled text-only">
            <template v-if="link.valid_to === null">
              {{ $t('unrestricted') }}
            </template>
            <template v-else>
              {{ $date(link.valid_to).format('DD.MM.YYYY, HH:mm') }}
            </template>
          </div>
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('sorting') }}
          </div>
          <div class="input-value disabled">
            <select
              id=""
              v-model="link.sorting"
              class="user-input select disabled inverted"
              name=""
            >
              <option
                v-for="(item) in sorting_options"
                :key="'share_option_' + item.name"
                :value="item.data"
              >
                {{ $t(item.name) }}
              </option>
            </select>
          </div>
        </div>
        <br>
        <div class="subtitle">
          {{ $t('access and security') }}
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('rating') }}
          </div>
          <div class="input-value readonly disabled text-only">
            <input
              type="checkbox"
              readonly
              :checked="link.evaluation"
            >
          </div>
        </div>

        <div class="input-group">
          <div class="input-label">
            {{ $t('comments') }}
          </div>
          <div class="input-value readonly disabled text-only">
            <input
              type="checkbox"
              readonly
              :checked="link.commenting"
            >
          </div>
        </div>

        <div
          class="input-group"
          v-if="typeof link.access_code !== 'undefined' && link.access_code !== null && link.access_code !== ''"
        >
          <div class="input-label">
            {{ $t('access code') }}
          </div>
          <div class="input-value selectable readonly text-only">
            {{ link.access_code }}
          </div>
        </div>


        <div class="input-group">
          <div class="input-label">
            {{ $t('force login') }}
          </div>
          <div class="input-value readonly disabled text-only">
            <input
              type="checkbox"
              readonly
              :checked="link.authenticated"
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      link: {},
      collection: {},
      loadingCollection: true,
      loading: true
    }
  },
  watch: {},
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
    sorting_options() {
      var options = [
        {
          name: 'manual order',
          value: 'manual order',
          data: 'sort_index.asc'
        },
        {
          name: 'name',
          value: 'name.asc',
          data: 'name.asc'
        }
      ];
      return options;
    },
    share_link: function () {
      if (this.link.url === null) {
        return null;
      }
      return this.link.url;
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load() {
      var id = this.dialog.properties.item.id;
      this.loading = true;
      this.loadingCollection = true;
      this.$store.dispatch('links/find', id)
          .then((response) => {
            this.link = response.data;
            this.loading = false;
            this.$store.dispatch('collections/load', response.data.collection_id)
                .then((response) => {
                  this.collection = response.data;
                  this.loadingCollection = false;
                })
          })
    }
  }
}
</script>