import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    mocking: false
  }),
  actions: {
    loadAssetContexts({rootGetters}, payload) {
      return new Promise((resolve) => {
        if (rootGetters['loggedIn'] === false) {
          return [];
        }

        var contexts = [];
        var item = {};
        var promises = [];

        // Get asset usages in collections
        var filter = [
          {
            property: "assets.asset_id",
            operator: "=",
            value: payload.asset_id
          }
        ]
        promises.push(axios.get('api/assets/' + payload.asset_id).then((response) => {
          item = response.data;
        })
        );
        promises.push(axios.get('api/asset-collections?filter=' + JSON.stringify(filter))
          .then((response) => {
            response.data.asset_collections.forEach((item) => {
              contexts.push(item);
            })
          }));

        Promise.allSettled(promises).then(() => {
          resolve({ item: item, contexts: contexts});
        });
      });
    },
    storeRating({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        var baseUrl = 'api/assets/' + payload.asset_id + '/comments';
        if (rootGetters['loggedIn'] === false && payload.lightbox === true) {
          baseUrl = 'front/lightbox/' + payload.link_id + '/assets/' + payload.asset_id + '/evaluations';
        }
        axios.post(baseUrl, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    storeComment({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        var url = 'api/assets/' + payload.asset_id + '/comments';
        if (rootGetters['loggedIn'] === false && payload.link_id !== null) {
          url = 'front/lightbox/' + payload.link_id + '/assets/' + payload.asset_id + '/comments';
        }

        axios.post(url, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    loadRatingStats(_, payload) {
      return new Promise((resolve, reject) => {
        var url = '';
        if (typeof payload.link_id != 'undefined') {
          url = 'front/lightbox/' + payload.link_id + '/assets/' + payload.asset_id + '/evaluations';
        } else {
          url = 'api/assets/' + payload.asset_id + '/evaluations';
        }

        if (typeof payload.pool !== 'undefined') {
          url += '?pool=1';
        }

        axios.get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    loadRatingList({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        var filter = [
          {
            property: "evaluation",
            operator: "exists"
          },
          {
            property: "evaluation_superseded",
            value: false
          }
        ];

        var baseUrl = 'api/assets/' + payload.asset_id + '/comments';

        if (rootGetters['loggedIn'] === false && typeof payload.link_id != 'undefined') {
          baseUrl = 'front/lightbox/' + payload.link_id + '/assets/' + payload.asset_id + '/comments';
        }

        axios.get(baseUrl + '?filter=' + JSON.stringify(filter))
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    show({rootGetters}, payload) {
      return new Promise((resolve, reject) => {

        // Aggregate filters
        var filters = [];
        var allowedFilters = ['categories', 'pool', 'link_id'];

        allowedFilters.forEach((item) => {
          if (typeof payload[item] !== 'undefined') {
            filters[item] = payload.categories;
          }
        });

        var query = '';

        filters.forEach((filter, index) => {
          query += index + '=' + filter + '&';
        });

        var filter = [];

        if (payload.collection_id !== null && payload.collection_id !== "") {
          filter.push({
            property: 'collection_id',
            operator: '=',
            value: payload.collection_id
          })
        } else if (payload.link_id === null) {
          filter.push({
            property: 'collection_id',
            operator: '=',
            "value": null
          })
        }

        if (payload.link_id !== null && payload.link_id !== "") {
          filter.push({
            property: 'link_id',
            operator: '=',
            value: payload.link_id
          })
        } else {
          filter.push({
            property: 'link_id',
            operator: '=',
            "value": null
          })
        }

        query += '&sort=[{"property":"created_on","direction":"asc"}]';
        var baseUrl = 'api/assets/' + payload.asset_id + '/comments';

        if (rootGetters['loggedIn'] === false && typeof payload.link_id != 'undefined') {
          baseUrl = 'front/lightbox/' + payload.link_id + '/assets/' + payload.asset_id + '/comments';
        }

        var url = baseUrl + '?filter=' + JSON.stringify(filter) + query;

        axios.get(url)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          });

      });
    }
  }
}
