<template>
  <div>
    <fieldset>
      <legend> {{ $t('configure filename') }}</legend>


      <div class="filename-example">
        <div class="example-label">
          {{ $t('filename preview') }}:
        </div>
        {{ displayFilename }}
      </div>


      <table class="table responsive inverted">
        <tr
          class="border-below"
          v-for="(item, index) in localFormat"
          :key="'formatrow_' + item.type + '_' + index"
        >
          <td>
            {{ index + 1 }}.
          </td>
          <td>
            {{ $t('filename_type.' + item.type) }}
          </td>
          <td class="small">
            <template
              v-for="(option, key) in item"
            >
              <template v-if="key !== 'type'">
                {{ $t('filename_option.' + key) }}
                <template v-if="key === 'digits' || key === 'startnumber'">
                  <input
                    :key="'numberinput_' + index + '_' + key"
                    class="user-input inverted"
                    type="number"
                    :placeholder="$t('filename_option.'+ key)"
                    v-model="localFormat[index][key]"
                  >
                </template>
                <template v-if="key === 'text'">
                  <input
                    :key="'textinput' + index + '_' + key"
                    class="user-input inverted"
                    type="text"
                    :placeholder="$t('filename_option.'+ key)"
                    v-model="localFormat[index][key]"
                  >
                </template>
              </template>
            </template>
          </td>
          <td class="align-right">
            <button
              class="button inverted small"
              @click="removeFileNamePart(index)"
            >
              {{ $t('remove') }}
            </button>
          </td>
        </tr>
        <div class="inline">
          <span />
        </div>
      </table>

      <div class="form-inline">
        <select
          name=""
          id=""
          class="user-input inverted"
          v-model="addoption"
        >
          <option
            :value="type.name"
            v-for="(type) in types"
            :key="'optiontype_' + type.name"
          >
            {{ $t('filename_type.' + type.name) }}
          </option>
        </select>
        <button
          class="button inverted"
          @click="addFileNamePart(addoption)"
        >
          {{ $t('add') }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: '',
          extension: ''
        }
      }
    },
    extension: {
      type: String,
      default: 'jpg'
    },
    value: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    originalFilename: function () {
      return this.asset.name.substring(0, this.asset.name.length - this.asset.extension.length - 1);
    },
    displayFilename: function () {
      var filename = '';
      this.localFormat.forEach((item) => {
        if (item.type === 'original') {
          filename += this.originalFilename;
        }
        if (item.type === 'extension_lowercase') {
          filename += '.' + this.extension.toLowerCase();
        }
        if (item.type === 'extension_uppercase') {
          filename += '.' + this.extension.toUpperCase();
        }
        if (item.type === 'text') {
          filename += item.text;
        }
        if (item.type === 'sequential_number') {
          var number = item.startnumber.toString().padStart(item.digits, '0');
          filename += number;
        }
      });
      return filename;


    }
  },
  methods: {
    addFileNamePart(type) {
      var item = {
        type: type,
      };

      if (type === 'sequential_number') {
        item.startnumber = 1;
        item.digits = 4;
      }

      if (type === 'text') {
        item.text = '';
      }

      this.localFormat.push(
          item
      )
    },
    removeFileNamePart(index) {
      this.localFormat.splice(index, 1);
    }
  },
  watch: {
    format: {
      handler: function () {
        this.localFormat = this.value;
      },
      deep: true
    },
    localFormat: {
      handler: function () {
        this.$emit('input', this.localFormat);
      },
      deep: true
    }
  },
  data: function () {
    return {
      addoption: 'text',
      localFormat: this.value,
      types: [
        {
          name: 'original',
        },
        {
          name: 'extension_lowercase'
        },
        {
          name: 'extension_uppercase'
        },
        {
          name: 'sequential_number',
          options: [
            {
              name: 'startnumber',
              type: 'number',
            }, {
              name: 'digits',
              type: 'number',
            },

          ]
        },
        {
          name: 'text',
          options: [
            {
              name: 'text',
              type: 'text'
            }
          ]
        }
      ]
    }
  },

}
</script>