export default {
    state: () => ({
        show: false,
        title: '',
        message: '',
        maxWidth: 550,
        actionComponent: 'Default',
        bodyComponent: '',
        modal: true,
        properties: {}
    }),
    mutations: {
        dialogState(state, payload) {
            state.modal = true;
            if (typeof payload.modal !== 'undefined') {
                state.modal = payload.modal;
            }
            if(typeof payload.width !== 'undefined'){
                state.maxWidth = payload.width;
            } else {
                state.maxWidth = 550;
            }
            state.show = payload;
        },
        dialogTitle(state, payload) {
            state.title = payload;
        },
        dialogMessage(state, payload) {
            state.message = payload;
        },
        dialogActionCompnent(state, payload) {
            state.actionComponent = payload;
        },
        dialogBodyCompnent(state, payload) {
            state.bodyComponent = payload;
        },
        dialogProperties(state, payload) {
            state.properties = payload;
        }
    },
    actions: {
        showDialog({commit}, payload) {
            commit('dialogState', payload);
        },
        hideDialog({commit}) {
            commit('dialogState', false);
        },
        dialogTitle({commit}, payload) {
            commit('dialogTitle', payload);
        },
        dialogMessage({commit}, payload) {
            commit('dialogMessage', payload);
        },
        dialogActionComponent({commit}, payload) {
            commit('dialogActionCompnent', payload);
        },
        dialogBodyComponent({commit}, payload) {
            commit('dialogBodyCompnent', payload);
        },
        dialogProperties({commit}, payload) {
            commit('dialogProperties', payload);
        },
        dialogResetProperties({commit}) {
            commit('dialogProperties', {});
        }
    },
    getters: {}
}