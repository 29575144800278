import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        search: '',
        page: 1,
        max_page: 1,
        sortby: 'sort_index',
        sortasc: true,
        selected: null
    }),
    mutations: {
        set: function (state, payload) {
            state.list = payload;
        },
        clear: function (state) {
            state.list = [];
        },
        maxPage: function (state, payload) {
            state.max_page = payload;
        },
        select: function (state, payload) {
            state.selected = payload;
        }
    },
    actions: {
        search({dispatch, state}, payload) {
            state.search = payload.search;
            state.page = 1;
            if(typeof payload.suspend !== 'undefined' && payload.suspend === true){
                return;
            }
            dispatch('index', {root: true});
        },
        page({dispatch, state}, payload) {
            state.page = payload.page;
            if(typeof payload.suspend !== 'undefined' && payload.suspend === true){
                return;
            }
            dispatch('index', {root: true});
        },
        sortBy({dispatch, state}, payload) {
            state.sortby = payload.sortby;
            state.sortasc = payload.sortasc;
            state.page = 1;
            dispatch('index', {root: true});
        },
        select({commit}, payload) {
            commit('select', payload);
        },
        index: function ({state, commit}) {
            return new Promise((resolve, reject) => {

                var query = '?page=' + state.page + '&page_size=1000&sort=[{"property":"' + state.sortby + '","direction":"' + (state.sortasc ? "asc" : "desc") + '"}]';
                if (state.search !== '') {
                    query += '&filter=[{"property":"name","operator":"like","value":"' + state.search + '"}]';
                }

                axios.get('api/tags' + query).then((response) => {
                    commit('set', response.data.tags);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        update(_, payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/tags/' + payload.tag.id,
                    payload.tag
                )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        store(_, payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/tags',
                    payload.tag
                )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        destroy(_, payload) {
            return new Promise((resolve, reject) => {
                axios.delete('api/tags/' + payload.id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        show(_, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/tags/' + payload.id)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        categories: (state) => {
            var categories = [];
            state.list.forEach((item) => {
                if (typeof item.category !== 'undefined' && item.category !== null && !categories.includes(item.category)) {
                    categories.push(item.category);
                }
            })
            categories.push(null);
            return categories;
        },
        list: (state) => {
            return state.list;
        },
        filteredList: (state) => (query, locale) => {
            return state.list.filter((item) => {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || (typeof item.locale != 'undefined' && typeof item.locale[locale] != 'undefined' && item.locale[locale].toLowerCase().includes(query.toLowerCase()));
            });
        }
    }
}
