import axios from "axios";
import TreeSearch from "@/helpers/TreeSearch";

export default {
    namespaced: true,
    state: () => ({
        items: [],
        selected_collection: null
    }),
    mutations: {
        setCollections(state, payload) {
            state.items = payload;
        },
        select(state, payload) {
            state.selected_collection = payload;
        },
        setItems(state, payload) {
            state.items = payload;
        },
        addItem(state, payload) {
            state.items.push(payload);
        }
    },
    actions: {
        download(_, payload) {
            return new Promise((resolve, reject) => {
                axios.post('front/collection/' + payload.collection_id + '/assets/' + payload.asset_id + '/downloads/original')
                  .then((response) => {
                      resolve({response: response, payload: payload });
                  }).catch((error) => {
                    reject(error);
                })
            })
        },
        index({commit}) {
            var requestUrl = 'api/asset-collections?page=1&page_size=1000&';
            axios.get(requestUrl)
                .then((response) => {
                    commit('setItems', response.data.asset_collections);
                }).catch(() => {
            });
        },
        loadAll() {
            return new Promise((resolve, reject) => {
            var requestUrl = 'api/asset-collections?page=1&page_size=1000&';
            axios.get(requestUrl)
              .then((response) => {
                  resolve(response);
              }).catch((error) => {
                  reject(error);
            });
            })
        },
        rename({dispatch}, payload) {
            axios.patch('api/asset-collections/' + payload.item.id, {name: payload.item.name})
                .then(() => {
                    dispatch('index');
                });
        },
        addAssets({rootState}, payload) {
            return new Promise((resolve, reject) => {
                var itemList = JSON.parse(JSON.stringify(payload.items));
                var chunks = [];
                var promises = [];
                var results = [];

                while (itemList.length > 0) {
                    const chunk = itemList.splice(0, rootState.assets.batchSize);
                    chunks.push(chunk);
                }

                var i = 0;

                function chunkProcess(i) {
                    if (i >= chunks.length) {
                        return;
                    }
                    promises.push(axios.put('api/asset-collections/' + payload.id + '/assets/' + chunks[i].join(','))
                      .then(() => {
                          chunkProcess(i += 1);
                      }));
                }

                chunkProcess(i)

                Promise.allSettled(promises).then(() => {
                    resolve(results);
                })
                  .catch((error) => {
                      reject(error);
                  });
            });
        },
        contextmenu({commit}, payload) {
            commit('assets/contextmenu', payload, { root: true });
        },
        select({commit}, payload) {
            commit('select', payload);
            commit('assets/clearSelection', null, { root: true });
        },
        get({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/asset-collections/' + payload.id)
                    .then((response) => {
                        resolve(response);
                        commit('addItem', response.data);
                    }).catch((error) => {
                    reject(error);
                });
            });
        },
        load(_, payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/asset-collections/' + payload)
                    .then((response) => {
                        resolve(response);
                    }).catch((error) => {
                    reject(error);
                });
            });
        },
        store({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
            axios.post('api/asset-collections', {
                name: payload.name, parent_id: payload.parent_id
            }).then((response) => {

                dispatch('index');
                commit('addNotification',
                    {
                        title: 'collection created',
                        class: 'highlight',
                        data: {
                            name: payload.name
                        },
                        datetime: new Date()
                    },
                    {root: true}
                );
                resolve(response.data);
            }).catch((error) => {
                commit('addNotification',
                    {
                        title: error.response.data.error,
                        class: 'error',
                        data: {
                            name: payload.name
                        },
                        datetime: new Date()
                    },
                    {root: true}
                );
                reject();
            })
            })
        },
        updateHeader(_, payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/asset-collections/' + payload.id, {header_content: payload.header_content, header_title: payload.header_title, preview: payload.preview})
                  .then((response) => {
                      resolve(response);
                  })
                  .catch((error) => {
                      reject(error);
                  });
            });
        },
        destroy(_, payload) {
            return new Promise((resolve, reject) => {
                axios.delete('api/asset-collections/' + payload.item).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        move({commit, dispatch}, payload) {
            // Parent node unchanged
            if (payload.source.parent_id === payload.target.id) {
                return false;
            }
            // Move into child node
            if (payload.source.descendants.includes(payload.target.id)) {
                commit('addNotification',
                    {
                        title: 'error moving into descendant',
                        class: 'error',
                        description: '',
                        datetime: new Date()
                    }
                );
                return false;
            }
            var data = {};
            // TODO: supply parent_id when sorting
            data[payload.target.id.includes("before") || payload.target.id.includes("after") ? 'sort_index' : 'parent_id'] = payload.target.id;
            axios.patch('api/collections/' + payload.source.id, data).then(() => {
                dispatch('index');
            });
        }
    },
    getters: {
        currentCollection: function (state) {
            if (state.selected_collection == null) {
                return {
                    'path': "/",
                    "name": "",
                    "descendants": []
                }
            }
            return TreeSearch(state.items, state.selected_collection);
        }
    }
}