<template>
  <div
    class="full-width"
    :key="'folder_permissions_key_' + $store.state.roles.updateKey"
  >
    <div class="folder-item bordered-bottom">
      <div class="folder-item-selector">
        <div class="inline-list ">
          <div class="icon-folder " />
          <div class="flex-1-1 bold ">
            {{ $t('folder') }}
          </div>
          <div class="flex-0-0 medium-width bold">
            {{ $t('allow') }}
          </div>
          <div class="flex-0-0 medium-width bold">
            {{ $t('deny') }}
          </div>
        </div>
      </div>
    </div>

    <FolderPermissionItem
      :tree="folders"
      :parent-policy="'root'"
      :key="'folder_permission_update_key_' + folder.id + '_' + $store.state.roles.updateKey"
      :folder="folder"
      v-for="(folder) in folders"
    />
    <br>
    <button
      class="button small secondary"
      @click="clearFolderPermissions"
    >
      {{ $t('clear folder permissions') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";
import FolderPermissionItem from "@/components/helpers/FolderPermissionItem";
import axios from "axios";
export default {
  components: {FolderPermissionItem},
  props: {
    value: {
      required: false,
      type: [Object, Array],
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      updateKey: 0,
      mounted: false,
      localValue: this.value,
      folders: []
    }
  },
  watch: {
    localValue: {
      handler: function () {
        this.$emit('input', this.localValue);

      },
      deep: true
    }
  },
  methods: {
    clearFolderPermissions() {
      delete this.$store.state.dialog.properties.role['folder_accesses'];
      // this.$store.state.dialog.properties.role['folder_accesses'] = null
      this.$store.state.roles.updateKey++;
    },
    loadFolders() {
      axios.get('api/folders?tree=true').then((response) => {
        this.folders = response.data.folders;
        this.mounted = true;
      })
    }
  },
  mounted: function () {
    this.loadFolders();
    this.localValue = this.value;
  },
  computed: {
    ...mapState({
      client: 'client',
      auth: 'auth'
    })
  }
}
</script>