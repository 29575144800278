<template>
  <div
    :key="'folder_' + folder.id + '_' + inheritedPolicy "
    class="folder-item"
  >
    <div class="folder-item-selector">
      <div class="inline-list no-padding">
        <div class="icon-folder" />
        <div
          class="flex-1-1"
          :class="{ dim : (isDisabled || localPolicy === false) && localPolicy !== true}"
        >
          {{ folder.name }}
        </div>
        <input
          class="larger flex-0-0  medium-width "
          :checked="localPolicy === true || localPolicy === null"
          :class="{ disabled: isDisabled }"
          :name="'folder_permission_' + folder.id"
          type="radio"
          @click.prevent="allow(folder.id)"
          :value="true"
        >
        <input
          class="larger flex-0-0  medium-width "
          :checked="localPolicy === false"
          :class="{ disabled: isDisabled }"
          :name="'folder_permission_' + folder.id"
          type="radio"
          @click.prevent="deny(folder.id)"
          :value="false"
        >
        <template v-if="inheritedPolicy !== null" />
      </div>
    </div>
    <div class="indented">
      <FolderPermissionItem
        :key="'child_folder_' + child.id + '_' + updatekey + '_' + JSON.stringify($store.state.dialog.properties.role.folder_accesses)"
        :parent-policy="localPolicy"
        :tree="tree"
        :folder="child"
        v-for="(child) in folder.children"
      />
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'FolderPermissionItem',
  data: function () {
    return {
      localFolder: this.folder,
      property: null,
      updatekey: 0,
    }
  },
  methods: {
    initialize() {
      if (typeof this.$store.state.dialog.properties.role.folder_accesses === 'undefined' || this.$store.state.dialog.properties.role.folder_accesses === null) {
        this.$store.state.dialog.properties.role.folder_accesses = {};
      }
    },
    allow(id) {
      this.initialize();
      this.$store.state.dialog.properties.role.folder_accesses[id] = true;
      this.$store.state.roles.updateKey++;
    },
    deny(id) {
      this.initialize();
      this.$store.state.dialog.properties.role.folder_accesses[id] = false;
      this.$store.state.roles.updateKey++;
    }
  },
  computed: {
    ...mapState({
      auth: 'auth'
    }),
    isDisabled() {
      if (this.parentPolicy === false) {
        return true;
      }
      return !(typeof this.$store.state.dialog.properties.role.folder_accesses !== 'undefined' && this.$store.state.dialog.properties.role.folder_accesses !== null && typeof this.$store.state.dialog.properties.role.folder_accesses[this.folder.id] !== 'undefined');
    },
    localPolicy() {
      if(typeof this.$store.state.dialog.properties.role.folder_accesses === 'undefined' || this.$store.state.dialog.properties.role.folder_accesses === null){
        return true;
      }
      if (this.parentPolicy === false) {
        return false;
      }
      if (typeof this.$store.state.dialog.properties.role.folder_accesses !== 'undefined' && this.$store.state.dialog.properties.role.folder_accesses !== null) {
        if (typeof this.$store.state.dialog.properties.role.folder_accesses[this.folder.id] !== 'undefined') {
          return this.$store.state.dialog.properties.role.folder_accesses[this.folder.id];
        } else {
          if (this.parentPolicy === 'root') {
            return 'root';
          }
          if (this.parentPolicy === true) {
            return true;
          }
        }
      }
      return true;
    },
    inheritedPolicy() {
      if (this.parentPolicy === false) {
        return false;
      }

      if (typeof this.$store.state.dialog.properties.role.folder_accesses !== 'undefined' && this.$store.state.dialog.properties.role.folder_accesses !== null && typeof this.$store.state.dialog.properties.role.folder_accesses[this.folder.id] !== 'undefined') {
        return this.localPolicy;
      }

      if (this.parentPolicy === null) {
        return this.localPolicy;
      }

      return this.parentPolicy;
    }
  },
  props: {
    folder: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    tree: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    parentPolicy: {
      required: false,
      type: Boolean,
      default: true
    }
  }
}
</script>