export default {
    state: () => ({
        items: [],
    }),
    mutations: {
        addNotification(state, payload) {
            state.items.push(payload);
        },
        removeNotification(state, payload) {
            state.items.splice(payload.index, 1);
        },
        removeStaleNotifications(state) {
            var active = state.items.filter((item) => new Date() - item.datetime < 5000);
            if (active.length === 0) {
                state.items.length = 0;
            }
        }
    },
    actions: {
        // TODO Load notifications from backend or connect to permanent socket
        /* notificationPoll({dispatch, commit}, payload) {
             setTimeout(() => {
               if(state.items.length > 0) {
                     commit('removeStaleNotifications');
                     dispatch('notificationPoll', payload);
                }

             }, 1000)
         }*/
    },
    getters: {}
}
