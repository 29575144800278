export default {
  namespaced: true,
  state: () => ({
    authentication: {
      verified: false
    },
    data: {
      assetfields: {},
      filters: {
        languagefilters: [],
        autocomplete: false
      }
    }
  }),
  mutations: {
    authenticate(state) {
      state.authentication.verified = true;
    }
  },
  actions: {
    processAssetFields({state, rootState}){
      var fields = {};
      rootState.client.asset_fields.forEach((assetfield) => {
        if(typeof assetfield.options.list !== 'undefined' && assetfield.options.list !== null) {
          fields[assetfield.name] = assetfield.options.list;
        } else {
          fields[assetfield.name] = assetfield.title;
        }
      });
      state.data.assetfields = fields;
    }
  },
  getters: {}
}