<template>
  <div>
    <div>
      <div class="property-item">
        <div class="property-title">
          <label>{{ $t('fileformat') }}</label>
        </div>
        <div class="property-value">
          <select
            v-model="settings.format"
            class="user-input inverted"
          >
            <option
              :key="'export_format_' + item"
              :value="item"
              v-for="(item) in assets.exportFormats"
            >
              {{ item.toUpperCase() }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <fieldset>
      <legend>{{ $t('image format properties') }}</legend>

      <div>
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('sizing') }}</label>
          </div>
          <div class="property-value">
            <select
              v-model="settings.sizing"
              class="user-input inverted"
            >
              <option value="original">
                {{ $t('original') }}
              </option>
              <option value="resize">
                {{ $t('resize') }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="settings.sizing === 'resize'">
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('width') }}</label>
          </div>
          <div class="property-value">
            <input
              v-model="settings.width"
              :placeholder="$t('width')"
              class="user-input inverted"
              min="0"
              max="16000"
              step="1"
              type="number"
            >
          </div>
        </div>
      </div>

      <div v-if="settings.sizing === 'resize'">
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('height') }}</label>
          </div>
          <div class="property-value">
            <input
              v-model="settings.height"
              :placeholder="$t('height')"
              class="user-input inverted"
              min="0"
              max="16000"
              step="1"
              type="number"
            >
          </div>
        </div>
      </div>

      <div v-if="includesFormat(settings.format, ['jpg'])">
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('quality') }}</label>
          </div>
          <div class="property-value">
            <div class="property-value">
              <input
                v-model="settings.format_options.quality"
                :placeholder="$t('quality')"
                class="user-input inverted"
                type="number"
                min="0"
                max="100"
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="includesFormat(settings.format, ['jpg'])">
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('progressive') }}</label>
          </div>
          <div class="property-value">
            <div class="property-value">
              <input
                v-model="settings.format_options.progressive"
                class="user-input checkbox inverted"
                type="checkbox"
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="includesFormat(settings.format, ['png', 'tiff'])">
        <div class="property-item">
          <div class="property-title">
            <label>{{ $t('transparency') }}</label>
          </div>
          <div class="property-value">
            <div class="property-value">
              <input
                v-model="settings.format_options.transparency"
                class="checkbox user-input inverted"
                type="checkbox"
              >
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      settings: this.value
    }
  },
  computed: {
    ...mapState({
      assets: 'assets'
    })
  },
  watch: {
    settings: {
      handler: function () {
        this.$emit('input', this.settings);
      },
      deep: true
    }
  },
  mounted: function () {
    this.settings = this.value;
  },
  methods: {
    includesFormat(current, possibilities) {
      return possibilities.includes(current)
    }
  }
}
</script>