<template>
  <div
    :class="{ active : ui.userMenu.active }"
    class="user-menu"
    @click="$store.commit('ui/setUserMenuState',false)"
  >
    <div class="avatar-info">
      <div class="user-avatar">
        <img
          alt=""
          src=""
        >
      </div>
      <div class="user-avatar-metadata">
        <div class="user-avatar-company">
          {{ client.company }}
        </div>
        <div class="user-avatar-name">
          {{ auth.user.username }}
        </div>
      </div>
    </div>

    <div class="nav-separator" />
    <nav>
      <ul class="user-menu-nav">
        <template v-if="$store.state.auth.user.modules.includes('assets')">
          <li>
            <router-link
              active-class="active"
              class="link"
              to="/assets"
            >
              {{ $t('asset-management') }}
            </router-link>
            <ul>
              <li>
                <router-link
                  active-class="active"
                  class="link"
                  to="/uploads"
                >
                  {{ $t('upload queue') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="active"
                  class="link"
                  to="/export"
                >
                  {{ $t('export') }}
                </router-link>
              </li>
              <li v-if="$store.state.auth.user.modules.includes('share')">
                <router-link
                  active-class="active"
                  class="link"
                  to="/shared-links"
                >
                  {{ $t('shared links') }}
                </router-link>
              </li>
              <li v-if="$store.state.auth.user.modules.includes('keywords')">
                <router-link
                  active-class="active"
                  class="link"
                  to="/keywords"
                >
                  {{ $t('keywords') }}
                </router-link>
              </li>
              <li v-if="$store.state.auth.user.modules.includes('cicd')">
                <a
                  class="link"
                  href=""
                >{{ $t('ci cd') }}</a>
              </li>
            </ul>
          </li>
        </template>
        <template v-if="$store.state.auth.user.modules.includes('documents')">
          <li>
            <router-link
              active-class="active"
              class="link"
              to="/documents"
            >
              {{ $t('documents') }}
            </router-link>
            <ul>
              <li
                v-for="(docType) in visiblePostTypes"
                :key="docType.type"
              >
                <router-link
                  active-class="active"
                  class="link"
                  :to="'/documents/' + docType.type"
                >
                  {{ postTypeName(docType) }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>
        <template
          v-if="$store.state.auth.user.modules.includes('externalurl') && typeof client.external_urls !== 'undefined' && client.external_urls.length > 0"
        >
          <li>
            <div
              class="link readonly"
            >
              {{ $t('links') }}
            </div>
            <ul>
              <li
                v-for="(externalUrl) in client.external_urls"
                :key="'external_service_' + externalUrl.name"
              >
                <router-link
                  active-class="active"
                  class="link"
                  :to="'/external/' + externalUrl.name"
                >
                  {{ externalUrl.title[$i18n.locale] }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>

        <template v-if="$store.state.auth.user.modules.includes('collaboration')">
          <li>
            <router-link
              class="link"
              to="/collaboration"
            >
              {{ $t('collaboration') }}
            </router-link>
          </li>
        </template>

        <template v-if="$store.state.auth.user.modules.includes('users')">
          <li>
            <router-link
              class="link"
              to="/users"
            >
              {{ $t('users management') }}
            </router-link>
          </li>
        </template>

        <template v-if="$store.state.auth.user.modules.includes('client')">
          <li>
            <router-link
              class="link"
              to="/client"
            >
              {{ $t('client') }}
            </router-link>
          </li>
        </template>

        <template v-if="$store.state.auth.user.modules.includes('client.public_page')">
          <li>
            <router-link
              class="link"
              to="/public_page"
            >
              {{ $t('public_page') }}
            </router-link>
          </li>
        </template>

        <template v-if="$store.state.auth.user.modules.includes('front')">
          <li>
            <router-link
              active-class="active"
              class="link"
              to="/front"
            >
              {{ $t('front page') }}
            </router-link>
          </li>
        </template>
      </ul>

      <template v-if="$store.state.auth.user.modules.includes('statistics')">
        <div class="nav-separator" />
        <nav>
          <ul class="user-menu-nav">
            <li>
              <router-link
                class="link"
                to="/statistics"
              >
                {{ $t('statistics') }}
              </router-link>
            </li>
          </ul>
        </nav>
      </template>

      <div class="nav-separator" />

      <ul class="user-menu-nav">
        <li>
          <router-link
            active-class="active"
            class="link"
            to="/profile"
          >
            {{ $t('profile') }}
          </router-link>
        </li>

        <li>
          <router-link
            active-class="active"
            class="link"
            to="/settings"
          >
            {{ $t('settings') }}
          </router-link>
        </li>
        <li v-if="$store.state.auth.user.modules.includes('help')">
          <a
            class="link"
            href="#"
            @click="$store.commit('help/helpState',true)"
          >
            {{ $t('help') }}
          </a>
        </li>
      </ul>

      <div class="nav-separator" />

      <ul class="user-menu-nav">
        <li>
          <div class="informational-item">
            {{ $t('language') }}
          </div>
        </li>
        <li>
          <div class="link unselectable inline-list">
            <div
              v-for="(item) in auth.languages"
              :key="'language_link_' + item"
              :class="{ active : $i18n.locale === item }"
              class="link no-padding"
              @click="setLanguage(item)"
            >
              {{ item.toUpperCase() }}
            </div>
          </div>
        </li>
      </ul>

      <div class="nav-separator" />

      <ul class="user-menu-nav">
        <div
          class="link"
          @click="logout"
        >
          {{ $t('logout') }}
        </div>
      </ul>
    </nav>
  </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState({
      auth: 'auth',
      documents: 'documents',
      client: 'client',
      ui: 'ui'
    }),
    roles: function () {
      return this.auth.user.roles;
    },
    visiblePostTypes() {
      return this.documents.postTypes.filter((item) => {
        if (typeof item.hidden === 'undefined' || item.hidden === false) {
          return item;
        }
      })
    }
  },
  mounted(){
    if(this.$store.state.auth.user.modules.includes('documents')) {
      this.$store.dispatch('documents/initializePostTypes');
    }
  },
  methods: {
    postTypeName(postType) {
      return postType.name[this.$i18n.locale] ?? postType.type;
    },
    setLanguage: function (language) {
      this.$i18n.locale = language;
    },
    logout: function () {
      this.$store.dispatch('logoutAsync', {})
          .then(() => {

          })
          .finally(() => {
            this.$router.push('/login');
          });
    }
  }
}
</script>