<template>
  <div class="dialog-content">
    {{ $t('delete assets question') }}<br>


    <template v-if="dialog.properties.contexts.length > 0">
      <br>
      <div class="inline-error inline-list">
        <div class="icon-exclamation" />
        {{ $t('assets in use by collections or shared links') }}
      </div>
      <br>
      <table>
        <template v-for="(context) in dialog.properties.contexts">
          <div :key="'usage_group_' + context.item.id">
            <div class="">
              <strong>{{ context.item.name }}</strong>
            </div>
            <ul>
              <template v-for="(usage) in context.usages">
                <li
                  :key="context.item.id + '_usage_' + usage.id"
                  v-if="typeof usage.name !== 'undefined'"
                >
                  {{ usage.name }}
                </li>
              </template>
            </ul>
          </div>
        </template>
      </table>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {}
  },
  computed: {
    ...mapState({
      dialog: 'dialog'
    })
  },
  mounted: function () {
  }
}
</script>