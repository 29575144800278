<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button danger"
      @click="defaultAction"
    >
      {{ $t('ok') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  methods: {
    defaultAction: function () {
      this.$store.commit('assets/page', 1);

      if(this.dialog.properties.type === 'folders'){
        this.$store.dispatch('folders/rename', { item: this.dialog.properties.item })
            .then(() => {
              this.$store.dispatch('folders/index');
              this.closeDialog();
            });
      } else if(this.dialog.properties.type === 'collections'){
        this.$store.dispatch('collections/rename', { item: this.dialog.properties.item });
        this.$store.dispatch('collections/index');
        this.closeDialog();
      }



    /*  if (this.dialog.properties.item.type === 'folders') {
        this.$store.dispatch('dialogMessage', this.$t('delete asset collection folder question'));
      } else {
        this.$store.dispatch('dialogMessage', this.$t('delete asset collection question'));
      }
      this.$store.dispatch('collections/rename', { item: this.dialog.properties.item });
      setTimeout(() => {
        this.$store.commit('assetCollectionsClear');
        this.$store.dispatch('collections/index');
       //  this.$store.dispatch('getFolders');
       //  this.$store.dispatch('assetCollectionsLoad', {type: 'collections', path: '/'});

      }, 200);
       this.$store.dispatch('collections/index');
     */
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>