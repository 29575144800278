import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    postTypes: [],
    counts: {},
    path: [],
    items: [],
    query: '',
    creationData: {
      assetId: null
    },
    pagination: {
      page: 1,
      page_count: 1,
      page_size: 25,
      total_items: 180
    }
  }),
  loading: false,
  mutations: {},
  getters: {
    createFromAsset(state) {
      return state.postTypes.filter((item) => {
        return item.create_from_asset === true;
      })
    }
  },
  actions: {
    getRelatedAssets({state}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/assets?filter=' + JSON.stringify(payload.filters))
          .then((response) => {
            resolve(response.data.assets);
          })
      })
    },
    upload({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {

        var formData = new FormData();
        payload.files.forEach((f) => {
          formData.append('file', f);
        })
        axios.post('api/' + payload.postType + '/posts/file', {name: payload.files[0].name, files: payload.files})
          .then((response) => {
            axios.put(response.data.upload_url, formData.get('file')[0],
              {
                transformRequest: [function (data, headers) {
                  delete headers.Authorization;
                  delete headers.UID

                  if (response.data.headers) {
                    Object.entries(response.data.headers).forEach(([key, value]) => {
                      headers[key] = value;
                    });
                  }
                  return formData.get('file'); // Required for binary upload
                }]
              })
              .then(() => {
                axios.post('api/' + payload.postType + '/posts', {
                  type: payload.postType,
                  file_type: payload.fileType,
                  file: {
                    id: response.data.id,
                    name: response.data.name
                  }
                }).then((postresponse) => {
                  resolve({file: response.data, response: postresponse.data});
                });
              }).catch((e) => {
            });
          })
          .catch((e) => {
            console.log(e);
          })
      });
    },
    createAndEdit({state}, payload) {

      return new Promise((resolve, reject) => {
        var postType = state.postTypes.find((item) => {
          if (item.type === payload.posttype) {
            return item;
          }
        });

        // Clone because .find() returns a reference and not a fresh copy
        postType = JSON.parse(JSON.stringify(postType));

        if (typeof postType !== 'undefined') {
          var defaults = postType.fields.map((field) => {
            // Override default value of type select where default is null
            if (field.automatic === false && field.default === null) {
              if (field.type === 'select' && (field.options.type === 'single' || field.options.type === 'multiple') && field.options.force_selection === true) {
                field.default = Object.keys(field.options.list)[0];
              } else if (field.type === 'select') {
                field.default = [];
              }
            }

            if (typeof field.default === 'string' && field.default.includes('::')) {
              var parts = field.default.split('::');
              if (typeof payload.related_object_data !== 'undefined' && typeof payload.related_object_data[parts[0]] !== 'undefined' && typeof payload.related_object_data[parts[0]][parts[1]] !== 'undefined') {
                field.default = payload.related_object_data[parts[0]][parts[1]];
              } else {
                field.default = null;
              }
            }
            if (field.automatic === false && field.default !== null) {
              return {
                'field': field.name,
                'value': field.default
              }
            } else {
              return false;
            }
          })

          var createData = {};
          defaults.forEach((item) => {
            createData[item.field] = item.value;
          })
          createData.type = payload.posttype;
          createData.fieldName = payload.fieldName
          resolve(createData);
        }
      });
    },
    addChild({state}, payload) {
      state.path.push({
        type: payload.type,
        id: payload.id
      })
    },
    deleteDocument({state}, payload) {
      return new Promise((resolve, reject) => {
        axios.delete('api/' + payload.type + '/posts/' + payload.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject();
          })
      })
    },
    addToSelection({state}, payload) {
      if (!state.selection.find.includes(payload)) {
        state.selection.push(payload);
      }
    },
    getFile({state}, payload) {
      return new Promise((resolve, reject) => {
        if (payload.id === '') {
          reject();
          return;
        }
        axios.get('api/' + payload.type + '/posts/' + payload.id + '/file')
          .then((response) => {
            resolve(response.data);
          })
      });
    },
    show({state}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/' + payload.type + '/posts/' + payload.id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject();
          })
      })
    },
    update({state}, payload) {
      return new Promise((resolve, reject) => {
        var postTypeData = state.postTypes.find((item) => {
          if (item.type === payload.type) {
            return item;
          }
        });
        var updatableFields = postTypeData.fields.filter((field) => {
          if (field.automatic !== true && (field.read_only === false || typeof payload.id === 'undefined')) {
            return field;
          }
        });

        var data = {};
        updatableFields.forEach((field) => {
          if (typeof payload[field.name] !== 'undefined') {
            data[field.name] = payload[field.name];
          }
        })

        if (typeof payload.id === 'undefined') {

          data.type = payload.type;
          axios.post('api/' + payload.type + '/posts', data)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          axios.patch('api/' + payload.type + '/posts/' + payload.id, data)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    setPage({state}, payload) {
      this.pagination.page = payload;
      this.load(post)
    },
    find({state}, payload) {
      return new Promise((resolve, reject) => {
        var url = 'api/' + payload.posttype + '/posts?filter=' + JSON.stringify(payload.filter);

        axios.get(url)
          .then((response) => {
            if(response.data.total_items > 0) {
              resolve(response.data.posts[0]);
              return;
            }
            reject(null);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    load({state}, payload) {
      return new Promise((resolve, reject) => {
        var query = '';

        if (typeof payload.requestOnly === 'undefined' && typeof payload.countOnly === 'undefined') {
          state.items.splice(0);
          state.pagination.page_size = 25;
          state.pagination.page_count = 1;
          state.pagination.total_items = 0;
          state.loading = true;

          if (state.query !== '') {
            query = '&query=' + state.query;
          }
        }

        var postType = state.postTypes.find((item) => {
          if (item.type === payload.posttype) {
            return item;
          }
        });

        var filter = [];
        if (typeof payload.filter !== 'undefined') {
          filter = payload.filter
        }
        var sorting = []

        var page_size = 25;


        if (typeof postType !== 'undefined') {
          page_size = postType.page_size;
          sorting.push({
            property: postType.sort.by,
            direction: postType.sort.direction
          });
        }

        if (typeof payload.page_size !== 'undefined') {
          page_size = payload.page_size
        }

        var page = state.pagination.page;

        if (typeof payload.page !== 'undefined') {
          page = payload.page
        }
        var postType = '';
        if (typeof payload.posttype !== 'undefined') {
          postType = payload.posttype + '/';
        }
        var url = 'api/' + postType + 'posts?filter=' + JSON.stringify(filter) + query + '&sort=' + JSON.stringify(sorting) + '&page=' + page + '&page_size=' + page_size;

        axios.get(url)
          .then((response) => {

            if (typeof payload.requestOnly !== 'undefined' && payload.requestOnly === true) {
              resolve(response.data.posts);
              return;
            }
            if (typeof payload.countOnly !== 'undefined' && payload.countOnly === true) {
              resolve(response.data.total_items);
              return;
            }

            state.loading = false;
            state.items.splice(0);
            state.pagination.page_size = response.data.page_size;
            state.pagination.page_count = response.data.page_count;
            state.pagination.total_items = response.data.total_items;

            response.data.posts.forEach((item) => {
              state.items.push(item);
            });
            resolve();
          })
          .catch((error) => {
            state.loading = false;
            reject(error);
          })
          .finally(() => {
          })
      })
    },
    initializePostTypes({state, commit, rootState}) {
      return new Promise((resolve) => {
        state.postTypes.splice(0);
        rootState.client.posts.forEach((post) => {
          var postType = JSON.parse(JSON.stringify(post));
          postType.page = 1;
          postType.filters = {};
          postType.sort = {by: 'number', direction: 'asc'};
          postType.count = 'Counting...';
          state.postTypes.push(postType);
        })
        resolve();
      })
    }
  }
}