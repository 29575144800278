<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('save') }}
    </button>
    <button
      class="button dialog-button danger"
      @click="removeHeader"
    >
      {{ $t('remove header') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog'
    }),
  },
  methods: {
    defaultAction: function () {
      if(document.activeElement.tagName === 'DIV' || document.activeElement.className === 'ql-editor'){
        // Prevent editor enter key from closing dialog
        return;
      }
      this.$store.dispatch('collections/updateHeader', this.dialog.properties)
          .then(() => {
            this.$store.dispatch('collections/index');
            this.closeDialog();
          });
    },
    removeHeader: function () {
      this.$store.dispatch('folders/updateHeader', { id: this.dialog.properties.id, preview: null, header_title: null, header_content: null})
          .then(() => {
            this.$store.dispatch('folders/index');
            this.closeDialog();
          });
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>