import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        items: [],
        search: '',
        selection: []
    }),
    mutations: {
        setSelection(state, payload) {
            state.selection.splice(0);
            if(Array.isArray(payload)){

            payload.forEach((item) => {
                state.selection.push(item);
            });

            }
        },
        setSearch(state, payload) {
            state.search = payload;
        },
        setItems(state, payload) {
            state.items = payload;
        }
    },
    actions: {
        index({state, commit}) {
            return new Promise((resolve, reject) => {

                var filters = [];
                var url = 'api/assets?';

                if (state.search !== '') {
                    filters.push({
                        property: 'name',
                        operator: 'like',
                        value: state.search
                    });
                }
                if (filters.length > 0) {
                    url += 'filter=' + JSON.stringify(filters);
                }
                url += '&page=1&page_size=1000';

                axios.get(url)
                    .then((response) => {
                        commit('setItems', response.data.assets);
                        resolve(response.data.assets);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
    getters: {
        selectionItems(state) {
            var selections = [];
            state.selection.forEach((itm) => {
                var found = state.items.find((item) => {
                    if (item.id === itm) {
                        return item;
                    }
                });
                if (typeof found !== 'undefined') {
                    selections.push(found);
                }
            });
            return selections;
        }
    }
}