import axios from "axios";
import TreeSearch from './../../helpers/TreeSearch';

export default {
  namespaced: true,
  state: () => ({
    items: [],
    flat: [],
    selected_folder: null
  }),
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    select(state, payload) {
      state.selected_folder = payload;
    }
  },
  actions: {
    select({commit, dispatch}, payload) {
      commit('select', payload);
      window.location.hash = '#folders:' + payload;
      commit('assets/page', 1, {root: true});
      dispatch('assets/load', null, {root: true});
    },
    store({dispatch}, payload) {
      axios.post('api/folders', {
        name: payload.name, parent_id: payload.parent_id
      })
        .then(() => {
          dispatch('index');
        })
    },
    index({commit}) {
      axios.get('api/folders')
        .then((response) => {
          commit('set', response.data.folders);
        });
    },
    loadTree() {
      return new Promise((resolve, reject) => {
        var requestUrl = 'api/folders?page=1&page_size=1000';
        axios.get(requestUrl)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
          reject(error);
        });
      })
    },
    loadAll({state}) {
      return new Promise((resolve, reject) => {
        var requestUrl = 'api/folders?page=1&page_size=1000&tree=false&sort=[{"property":"path","direction":"asc"}]';
        axios.get(requestUrl)
          .then((response) => {
            state.flat = response.data.folders;
            resolve(response);
          }).catch((error) => {
          reject(error);
        });
      })
    },
    destroy({dispatch, commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.delete('api/folders/' + payload.id).then((response) => {
          commit('addNotification',
            {
              title: 'folder deleted',
              class: 'highlight',
              data: {},
              datetime: new Date()
            }
          );
          dispatch('index');
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    },
    move({commit, dispatch}, payload) {
      // Parent node unchanged
      if (payload.source.parent_id === payload.target.id) {
        return false;
      }
      // Move into child node
      if (payload.source.descendants.includes(payload.target.id)) {
        commit('addNotification',
          {
            title: 'error moving into descendant',
            class: 'error',
            description: '',
            datetime: new Date()
          }
        );
        return false;
      }

      var data = {};
      data[payload.target.id !== null && (payload.target.id.toString().includes("before") || payload.target.id.toString().includes("after")) ? 'sort_index' : 'parent_id'] = payload.target.id;
      axios.patch('api/folders/' + payload.source.id, data).then(() => {
        dispatch('index');
      });
    },
    updateHeader(_, payload) {
      return new Promise((resolve, reject) => {
        axios.patch('api/folders/' + payload.id, {header_title: payload.header_title, header_content: payload.header_content})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    rename(_, payload) {
      return new Promise((resolve, reject) => {
        axios.patch('api/folders/' + payload.item.id, {name: payload.item.name})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  getters: {
    currentFolder: function (state) {
      if (state.selected_folder == null) {
        return {
          "path": "/",
          "name": "",
          "descendants": [],
          "id": null
        }
      }
      return TreeSearch(state.items, state.selected_folder);
    },
    ascendants: function (state, getters) {
      var ascendants = [];
      var search = getters.currentFolder;
      ascendants.push(search);
      if (typeof search === 'undefined' || typeof search.parent_id === 'undefined') {
        return ascendants;
      }
      var next = TreeSearch(state.items, search.parent_id);

      if (typeof next !== 'undefined' && next !== null) {
        ascendants.unshift(next);
      }
      while (typeof next !== 'undefined' && next !== null && next.parent_id !== null) {
        next = TreeSearch(state.items, next.parent_id);
        ascendants.unshift(next);
      }
      return ascendants;
    }
  }
  ,
  mixins: {
    actions: {}
  }
}