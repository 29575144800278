<template>
  <div class="dialog-content">
    <template
      v-for="(item, index) in assetTypesCount"
    >
      <div
        v-if="supportedTypes.includes(item.type)"
        :key="'body_export_' + index"
        class="dialog-tab"
      >
        <div class="dialog-tab-header">
          <div class="dialog-tab-title">
            {{ $t('asset type ' + item.type) }} ({{ item.count }})
          </div>
          <div class="dialog-tab-switch">
            <div class="icon-chevron_up" />
          </div>
        </div>
        <div
          class="dialog-tab-content inverted"
          v-if="typeof properties.exportSettings[item.type] !== 'undefined'"
        >
          <component
            :is="components[item.type]"
            v-model="properties.exportSettings[item.type]"
          />
        </div>
      </div>
    </template>
    <template v-if="typeof properties.exportSettings.image !== 'undefined'">
      <FilenamingSelector
        v-model="properties.exportSettings.naming"
        :extension="properties.exportSettings.image.format || '.jpg'"
        :asset="this.assetList[0]"
      />
    </template>
    <div
      v-if="assetUnsupportedCount > 0"
      class="dialog-notice with-icon"
    >
      <div class="icon-exclamation icon-large" />
      {{ $t('n unsupported asset types in selection', {count: assetUnsupportedCount}) }}
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import ExportOptionsImage from './../../layout/assets/export/ExportOptionsImage';
import ExportOptionsAudio from './../../layout/assets/export/ExportOptionsAudio';
import ExportOptionsVideo from './../../layout/assets/export/ExportOptionsVideo';
import FilenamingSelector from "@/components/helpers/FilenamingSelector";

export default {
  components: {
    ExportOptionsImage, ExportOptionsAudio, ExportOptionsVideo, FilenamingSelector
  },
  data: function () {
    return {
      properties: {
        exportSettings: {
        },
        items: []
      },
      loaded: false,
      components: {
        image: 'ExportOptionsImage',
        audio: 'ExportOptionsAudio',
        video: 'ExportOptionsVideo',
      },
      supportedTypes: ['image', 'video', 'audio']
    }
  },
  mounted: function () {
    this.properties = this.dialog.properties;
    this.loaded = true;
  },
  watch: {
    properties: {
      handler: function () {
        if(this.loaded === true) {
          this.$store.commit('dialogProperties', this.properties);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      assets: 'assets',
    }),
    assetUnsupportedCount: function () {
      var count = 0;
      this.assetTypesCount.map((item) => {
        if (!this.supportedTypes.includes(item.type)) {
          count += item.count;
        }
      });
      return count;
    },
    assetList: function () {
      return this.assets.items.filter((item) => {
        return (this.dialog.properties.items.includes(item.id));
      })
    },
    assetTypesCount: function () {
      var mediaTypesCount = [];
      var items = this.assets.items.filter((item) => {
        return (this.dialog.properties.items.includes(item.id));
      }).map((item) => {
        var type = item.mime_type.split('/');

        return {type: type[0], count: 1};

      });
      items.forEach((item) => {

        var index = mediaTypesCount.findIndex((itm) => {
          return itm.type === item.type
        });
        if (index >= 0) {
          mediaTypesCount[index].count++;
        } else {
          mediaTypesCount.push(item);
        }
      })
      return mediaTypesCount;
    },
    getAvailableCollection: function () {
      var list = [];
      for (var i = 0; i < this.assets.assetCollections.collections.length; i++) {
        this.assets.assetCollections.collections.map((v) => {
          v.items.map((a) => {
            list.push(a);
          });
        })
      }
      return list;
    },
  }
}
</script>