export default {
    state: () => ({
        dragging: false,
        mousedown: false,
        sourceGroup: '',
        source: {},
        target: {},
        cursorLocation: {x: 0, y: 0}
    }),
    mutations: {
        mouse: function (state, payload) {
            state.mousedown = payload;
        },
        cursorLocation: function (state, payload) {
            state.cursorLocation = payload;
        },
        drag: function (state, payload) {
            state.dragging = payload;
        },
        source: function (state, payload) {
            state.source = payload;
        },
        target: function (state, payload) {
            state.target = payload;
        },
        sourceGroup: function (state, payload) {
            state.sourceGroup = payload;
        }
    },
    actions: {
        setMouse({commit}, payload) {
            commit('mouse', payload);
        },
        setDrag({commit, state}, payload) {
            if (state.dragging !== payload) {
                commit('drag', payload);
            }
        },
        setSource({commit}, payload) {
            commit('source', payload);
            // commit('sourceGroup')
        },
        setTarget({commit}, payload) {
            commit('target', payload);
        },
        setSourceGroup({commit}, payload) {
            commit('sourceGroup', payload);
        },
    },
    getters: {
        isDragging: function (state) {
            return state.dragging;
        },
        isMouseDown: function (state) {
            return state.mousedown;
        },
        source: function (state) {
            return state.source;
        },
        sourceGroup: function (state) {
            return state.sourceGroup;
        },
        target: function (state) {
            return state.target;
        },
        cursorLocation: function (state) {
            return state.cursorLocation;
        }
    }
}