import axios from "axios";

export default {
  state: () => ({
    address: {},
    self_registration: false,
    self_registration_active: false,
    self_registration_auto_pass: false,
    self_registration_role: "guest",
    email_for_username: false,
    user_fields: [],
    user_actions: {
      register: false,
      reset_password: true,
      login: false
    },
    base_configuration: "main",
    email: "",
    debugging: {
      log_prefix: "mandant"
    },
    asset_headers: {
      collections: [],
      folders: []
    },
    features: {
      share_expiration: {
        allow_infinite: true,
        allow_date: true
      },
      share: {
        collections: false,
        file: false,
        folder: false,
        basket: false,
        keywords: false,
      },
      languages: {
        "de_CH": {
          title: "",
          locale: {
            de: "",
            en: ""
          }
        }
      }
    },
    filters: {
      mode: "inclusive",
      override_base_filters: false,
      options: []
    },
    sorters: {
      override_base_filters: false,
      options: []
    },
    modules: [],
    filter_engine: "server",
    defaults: {
      allow_ratings: false,
      allow_comments: false,
      show_ratings: false,
      show_comments: false,
      allow_downloads: false
    },
    media: {
      images: {
        presets: []
      },
      video: {
        presets: []
      }
    },
    posts: [],
    password_change_on_first_login: true,
    roles: [],
    storages: [],
    cdn: {
      type: ""
    },
    purposes: [],
    asset_fields: [],
    company: "",
    created_on: '',
    domain: '',
    id: '',
    title: '',
    updated_on: ''
  }),
  mutations: {
    resetClient(state) {
      state.address = {};
      state.company = {};
      state.created_on = '';
      state.domain = '';
      state.id = '';
      state.title = '';
      state.updated_on = '';
    },
    updateClient(state, payload) {
      var keys = Object.keys(payload);
      for (var i = 0; i < keys.length; i++) {
        state[keys[i]] = payload[keys[i]];
      }
    }
  },
  actions: {
    updateClient({commit, dispatch, state}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('front/client')
          .then((response) => {

            commit('updateClient', response.data);
            document.title = state.title;
            if (typeof payload != 'undefined' && typeof payload.dispatch != 'undefined') {
              dispatch("useLogin", payload.dispatch.login);
            }
            resolve();
          }).catch(() => {
          reject();
        });
      });
    },
    storeClient({dispatch, state}, payload) {
      return new Promise((resolve, reject) => {
        axios.patch('api/clients/' + state.id, payload)
          .then(() => {
            dispatch('updateClient')
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    rebuildCache() {
      return new Promise((resolve, reject) => {
        axios.delete('api/clients/public/cache')
          .then(() => {
            axios.post('api/clients/public/cache')
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              })
          }).catch((error) => {
          reject(error);
        })
      })
    },
  },
  getters: {
    hasRatingField(state) {
      return state.asset_fields.find((item) => {
        if (item.name === 'rating' && item.options.type === 'star' || item.options.type === 'heart') {
          return true;
        }
      });
    }
  }
}