<template>
  <div class="dialog-actions">
    <button
      class="button dialog-button highlight"
      @click="defaultAction"
    >
      {{ $t('replace') }}
    </button>
    <button
      class="button dialog-button"
      @click="closeDialog"
    >
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script>

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      dialog: 'dialog',
      assetSelector: 'assetSelector'
    }),
  },
  methods: {
    defaultAction: function () {
      if (this.assetSelector.selection.length === 0) {
        alert(this.$t('no asset selected'));
        return;
      }
      var promises = [];

      this.dialog.properties.items.forEach((item) => {
        promises.push(this.$store.dispatch('assets/setPreviewAsset', {
          id: item,
          thumbnail_asset_id: this.assetSelector.selection[0]
        }));
      });

      Promise.allSettled(promises).then(() => {
        this.$store.dispatch('hideDialog');
        this.$store.dispatch('assets/load');
      });
    },
    closeDialog: function () {
      this.$store.dispatch('hideDialog');
    }
  }
}
</script>