<template>
  <div class="dialog-content">
    <input
      ref="input"
      v-model="properties.item.name"
      :placeholder="original_name"
      class="user-input inverted"
      type="text"
    >
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      properties: {
        item: {
          name: ''
        }
      },
      original_name: ''
    }
  },
  mounted: function () {
    this.properties = JSON.parse(JSON.stringify(this.dialog.properties));
    this.original_name = this.dialog.properties.item.name.toString();
    this.$refs.input.focus();
    this.$refs.input.select();
  },
  watch: {
    properties: {
      handler: function () {
        this.$store.commit('dialogProperties', this.properties);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      assets: 'assets',
    }),
    getAvailableCollection: function () {
      var list = [];
      for (var i = 0; i < this.assets.assetCollections.collections.length; i++) {
        this.assets.assetCollections.collections.map((v) => {
          v['items']['asset-collections'].map((a) => {
            list.push(a);
          });
        })
      }
      return list;
    },
  }
}
</script>