<template>
  <div class="dialog-content">
    <div class="form-structure">
      <input
        v-model="folder.name"
        :placeholder="$t('name')"
        class="user-input inverted"
        type="text"
      >
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      folder: {
        name: '',
        parent_id: null
      },
      item : {}
    }
  },
  watch: {
    folder: {
      handler: function () {
        this.$store.commit('dialogProperties', this.folder);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog'
    })
  },
  mounted: function () {
    if (this.dialog.properties.item.item.id != null) {
      this.folder.parent_id = this.dialog.properties.item.item.id;
    }
    this.item = this.dialog.properties.item.item;
  }
}
</script>