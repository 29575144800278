<template>
  <div class="dialog-content">
    <div
      class="form-structure"
      v-if="$store.state.auth.user.modules.includes('assets.uploads.global')"
    >
      <div class="input-value">
        <label>
          <input
            class="user-input checkbox inverted"
            type="checkbox"
            v-model="transfers.options.show_all_uploads"
          >
          {{ $t('show all uploads') }}<br>

          <small>{{ $t('show all uploads description') }}</small>
        </label>
      </div>
      <br>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {
    }
  },
  watch: {
    'transfers.options.show_all_uploads' : {
      handler: function(){
        this.$store.dispatch('updateUploaded');
      }
    }
  },
  computed: {
    ...mapState({
      dialog: 'dialog',
      transfers: 'transfers',
      auth: 'auth'
    }),
    roles: function () {
      return this.auth.user.roles;
    }
  }
}
</script>